import RequestsStatsView from "@/views/request-stats/RequestsStatsView";
import {getUser} from "@/utils/local";

const requestStatsRoutes = [
    getUser()?.scopes?.includes('request-stats:view') ?
    {
        name: 'RequestStats',
        path: '/request-stats',
        component: RequestsStatsView
    } : null,
];

export const requestStatsRouter = requestStatsRoutes.filter(function(x) {
    return x !== null;
})

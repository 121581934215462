import {getUser} from "@/utils/local";
import ReviewSitesView from "./ReviewSitesView.vue";
import ReviewSiteForm from "./ReviewSiteForm.vue";

const reviewSitesRoutes = [
  getUser()?.scopes?.includes('review-sites:view') ?
  {
    name: 'reviewSites',
    path: '/review-sites',
    component: ReviewSitesView
  } : null,
  getUser()?.scopes?.includes('review-sites:edit') ?
      {
        name: 'editReviewSites',
        path: '/review-site',
        component: ReviewSiteForm
      } : null,
];

export const reviewSitesRouter = reviewSitesRoutes.filter(function(x) {
  return x !== null;
})

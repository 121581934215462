import CustomersView from "@/views/customers/CustomersView";
import CustomerDetail from "@/views/customers/CustomerDetail";
import {getUser} from "@/utils/local";

const customersRoutes = [
    getUser()?.scopes.includes('customers:view') ?
    {
        name: 'Customers',
        path: '/customers',
        component: CustomersView
    } : null,
    getUser()?.scopes.includes('customers:detail') ?
    {
        name: 'Customer',
        path: '/customer',
        component: CustomerDetail
    } : null,
];

export const customersRouter = customersRoutes.filter(function (x) {
    return x !== null;
})

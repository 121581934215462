<template>
    <div>
        <div class="dashboard-cards">
            <stat-card :loading="loading" :value="dashboardCards.customers" color="blue" title="Customers"
                       @applyFilter="applyFilterGames"/>

            <stat-card :loading="loading" :value="dashboardCards.locations" color="purple" title="Locations"/>

            <stat-card :loading="loading" :value="dashboardCards.reviews" color="green" title="Reviews"/>

            <stat-card :loading="loading" :value="dashboardCards.active_campaigns" color="pink"
                       title="Active Campaigns"/>
        </div>
        <v-card class="pa-4" style="min-height: 100px">
            <p class="span-2 app_info_title">App Info</p>
            <v-row>
                <v-col lg="3" md="6" sm="12">
                    <v-text-field v-model="androidAppVersion"
                                  dense
                                  label="Android App Version"
                                  outlined
                                  readonly
                    />
                </v-col>

                <v-col lg="3" md="6" sm="12">
                    <v-text-field v-model="androidBuildNumber"
                                  dense
                                  label="Android Build Number"
                                  outlined
                                  readonly
                    />
                </v-col>

                <v-col lg="3" md="6" sm="12">
                    <v-text-field v-model="iosAppVersion"
                                  dense
                                  label="Ios App Version"
                                  outlined
                                  readonly
                    />
                </v-col>

                <v-col lg="3" md="6" sm="12">
                    <v-text-field v-model="iosBuildNumber"
                                  dense
                                  label="Ios Build Number"
                                  outlined
                                  readonly
                    />
                </v-col>
            </v-row>

        </v-card>
    </div>
</template>

<script>

import StatCard from "@/components/dashboard/StatCard";
import {DashboardService} from "@/services/dashboard-service";
// import {oneSignalApiKey} from "@/utils/local";
// import crypto from 'crypto'
import {fetchAndActivate, getValue} from "firebase/remote-config";
import {remoteConfig} from "../firebase/firebase";


export default {
    name: 'Home',
    components: {StatCard},
    data: () => ({
        loading: false,
        gamesLoading: false,
        loadingDialog: false,
        dashboardCards: {},
        games: 0,
        service: new DashboardService(),
        fetchQuestions: false,
        fetchGames: false,
        fetchUsers: false,
        fetchModes: false,

        // app info
        androidAppVersion: '',
        androidBuildNumber: '',
        iosAppVersion: '',
        iosBuildNumber: ''
    }),
    async mounted() {
        // try {
        //     const token = localStorage.getItem('auth_token');
        //     const user = token ? JSON.parse(localStorage.getItem('auth_user')) : null;
        //     this.$OneSignal.init({
        //         appId: "01abea2e-9aef-4520-b28e-53f2c77838d7",
        //         safari_web_id: "web.onesignal.auto.3437296f-1581-4c9c-99a7-ef947df2b18c",
        //         promptOptions: {
        //             siteName: 'Reputation Rooster Admin',
        //             actionMessage: "We'd like to show you notifications for the latest updates.",
        //             exampleNotificationTitle: 'New Review',
        //             exampleNotificationMessage: 'User johndoa123@gmail.com  has received a review.',
        //             exampleNotificationCaption: 'You can unsubscribe anytime',
        //             acceptButtonText: "ALLOW",
        //             cancelButtonText: "NO THANKS",
        //             autoAcceptTitle: 'Click Allow',
        //             slidedown: {
        //                 prompts: [
        //                     {
        //                         type: "push",
        //                         autoPrompt: true,
        //                         text: {
        //                             actionMessage: "We'd like to show you notifications for the latest updates.",
        //                             acceptButton: "Allow",
        //                             cancelButton: "No Thanks"
        //                         },
        //                         delay: {
        //                             pageViews: 1,
        //                             timeDelay: 5
        //                         }
        //                     }
        //                 ]
        //             }
        //         },
        //         welcomeNotification: {
        //             disable: false,
        //             title: 'Welcome to Reputation Rooster admin',
        //             message: 'Thanks for subscribing. Now you can stay up to date.'
        //         },
        //         notifyButton: {
        //             enable: true,
        //             size: 'medium',
        //             showCredit: false,
        //         },
        //         subdomainName: "rr",
        //     }).then(async () => {
        //         const hmac = crypto.createHmac('sha256', oneSignalApiKey).update(user._id.toString());
        //         this.$OneSignal.setExternalUserId(user._id.toString(), hmac.digest('hex')).then(() => {
        //             console.log('user id set')
        //         }).catch((e) => {
        //             console.log('id set error')
        //             console.log(e)
        //         })
        //         await this.$OneSignal.sendTag('isAdmin', true, () => {
        //             console.log('isAdmin set')
        //         })
        //     }).catch((e) => {
        //         console.log(e)
        //     });
        // } catch (e) {
        //     console.log(e)
        // }

        // this.getData()
        await this.getDashboardData()
        await this.loadAppInfoFromFirebase(remoteConfig)

    },
    methods: {
        async loadAppInfoFromFirebase(remoteConfig) {
            try {
                remoteConfig.settings.minimumFetchIntervalMillis = 3600;
                const isFetched = await fetchAndActivate(remoteConfig);
                if(isFetched) {
                    this.androidAppVersion = await getValue(remoteConfig, "android_app_version")._value;
                    this.androidBuildNumber = await getValue(remoteConfig, "android_build_number")._value;
                    this.iosAppVersion = await getValue(remoteConfig, "ios_app_version")._value;
                    this.iosBuildNumber = await getValue(remoteConfig, "ios_build_number")._value;
                    const arr = [this.androidAppVersion, this.androidBuildNumber, this.iosAppVersion, this.iosBuildNumber];
                    console.log('arr before: ', arr)
                }
            } catch (e) {
                console.log('Error while loading remoteConfig: ', e)
            }
        },
        delay(ms) {
            return new Promise(res => setTimeout(res, ms))
        },
        async getDashboardData() {
            this.loading = true
            // await this.applyFilterGames()
            this.dashboardCards = await this.service.fetchStat()
            this.loading = false
            this.fetchQuestions = true
        },
        async applyFilterGames(data = null) {
            this.gamesLoading = true
            const games = await this.service.fetch(5, data)
            this.games = games.games
            this.gamesLoading = false
        }
    },

};
</script>

<style scoped>
.dashboard-cards {
    display: grid;
    grid-template-columns: 1fr;
    grid-row-gap: 10px;
    grid-column-gap: 10px;
    margin-bottom: 30px;
}

.dashboard-graphs {
    display: grid;
    grid-template-columns: 1fr;
    grid-row-gap: 10px;
    grid-column-gap: 10px;
    margin-bottom: 30px;
}

.dashboard-graphs-one {
    display: grid;
    grid-template-columns: 1fr;
    grid-row-gap: 10px;
    grid-column-gap: 10px;
    margin-bottom: 30px;
}

@media screen and (min-width: 580px) {
    .dashboard-cards {
        grid-template-columns: 1fr 1fr;
    }

    .dashboard-graphs {
        grid-template-columns: 1fr 1fr;
    }

    .dashboard-graphs-one {
        grid-template-columns: 1fr 1fr;
    }
}

@media screen and (min-width: 1000px) {
    .dashboard-cards {
        grid-template-columns: 1fr 1fr 1fr 1fr;
    }

    .dashboard-graphs {
        grid-template-columns: 1fr 2fr;
    }

    /*.dashboard-graphs-one {*/
    /*  grid-template-columns: 2fr 1fr;*/
    /*}*/
}

.app_info_title {
    font-weight: normal !important;
    font-size: 27px;
    padding-bottom: 20px;
    font-family: google-sans, sans-serif;
}

</style>

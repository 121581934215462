import LinksView from './LinksView.vue';
import {getUser} from "@/utils/local";

export const linksRoutes = [
    getUser()?.scopes?.includes('links:view') ?
        {
            name: 'Links',
            path: '/links',
            component: LinksView
        } : null,
];

export const linksRouter = linksRoutes.filter(function (x) {
    return x !== null;
})


<template>
  <div>
    <data-table
        :loader="loadData"
        :headers="headers"
        title="Recent Reviews"
        :custom-filter="customFilter"
        :allow-add="false"
        @add-new="() => {}"
        @done="$router.back()"
    >
      <template v-slot:company="{item}">
        {{ item && item.company_id ? item.company_id.name : '' }}
      </template>
      <template v-slot:review-text="{item}">
        <span v-if="item.review.description && item.review.description.length > 150">{{
            item.review.description.toString().substring(0, 150)
          }}...</span>
        <span v-else-if="item.review.description">{{ item.review.description }}</span>
      </template>
      <template v-slot:rating="{item}">
        <v-rating dense small color="amber" background-color="amber" :value="item.review.rating"></v-rating>
      </template>
      <template v-slot:date="{item}">
        {{ formatDate2(item.review.review_date, 'MM/DD/YYYY hh:mm a') }}
      </template>
      <template v-slot:actions="{item}">
        <v-icon
            small
            @click="openReview(item)"
            color="white"
            style="padding: 5px; border-radius: 6px; background: rgba(0,0,0,0.4)"
        >
          mdi-clipboard
        </v-icon>
      </template>
    </data-table>
    <v-dialog v-model="reviewDialog" width="50%">
      <v-card v-if="review" class="px-6 py-4">
        <h3 class="mb-2">Review Detail</h3>
        <div>
          <h5 class="mb-2">Rating</h5>
          <v-rating class="mb-2" :value="review.review.rating" dense color="amber" background-color="amber"></v-rating>
          <h5 v-if="review.review.description">Review</h5>
          <p>{{ review.review.description }}</p>
          <h5>Reviewer Name</h5>
          <p>{{ review.review.reviewer_name }}</p>
        </div>
        <h3 class="mb-2">Company Detail</h3>
        <div>
          <h5>Name</h5>
          <p>{{ review.company_id.name }}</p>
          <h5>Email</h5>
          <p>{{ review.company_id.email }}</p>
          <h5>Phone</h5>
          <p>{{ review.company_id.phone }}</p>
        </div>
      </v-card>
    </v-dialog>
    <loading-dialog v-model="loadingDialog" message="Loading..."/>
    <ErrorDialog v-model="error" :error="errorVal"/>
  </div>
</template>

<script>
import DataTable from '../../components/DataTable';
import LoadingDialog from "../../components/LoadingDialog";
import {getUser, formatDate2} from '@/utils/local';
import ErrorDialog from "@/components/ErrorDialog";
import {RecentReviewsService} from "@/services/recent-reviews-service";


export default {
  components: {ErrorDialog, LoadingDialog, DataTable},

  data: () => ({
    loading: false,
    loadingDialog: false,
    service: new RecentReviewsService(),

    error: false,
    errorVal: {},

    reviewDialog: false,
    review: null,

    headers: [
      {
        text: 'Company',
        value: 'company',
        sortable: false
      },
      {
        text: 'Review Text',
        value: 'review-text',
        width: '50%',
        sortable: false
      },
      {
        text: 'Review Rating',
        value: 'rating'
      },
      {
        text: 'Date',
        value: 'date'
      },
      {
        text: 'Actions',
        value: 'actions',
        width: '50px',
        align: 'right',
      },
    ]
  }),

  methods: {
    getUser,
    formatDate2,
    loadData() {
      return this.service.fetchAll();
    },
    openReview(item) {
      this.reviewDialog = true
      this.review = item
    },
    customFilter(value, search, item) {
      return search != null && ((item && item.company_id && item.company_id.name.toLowerCase().includes(search.toLowerCase())) || ((item && item.review && item.description && item.review.description && item.review.description.toLowerCase().includes(search.toLowerCase()))))
    },
  }
};
</script>

<style lang="sass" scoped>
.chip
  font-weight: 500
  padding: 5px 10px
  border-radius: 50px
  background: lightgray
</style>

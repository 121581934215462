<template>
  <div class="main">
    <div :class="`background bg${bg}`"></div>
    <v-card class="sign-in" elevation="10">
      <img src="../../assets/logo.png" alt="Logo" class="sign-in__logo"/>

      <h1 class="sign-in__title">Authenticate yourself</h1>
      <p class="sign-in__message">
        You need a Reputation Rooster admin account to access the content ahead, if you do not
        have an account or have forgotten your password then you can contact the
        support regarding your issue
      </p>
      <v-form ref="form">
        <v-text-field
            v-model="username"
            label="Your Email"
            outlined
            :rules="[required(), email()]"
        />
        <v-text-field
            v-model="password"
            :rules="[required('Do not leave this field empty')]"
            @keypress.enter="signIn"
            label="Your Password"
            :type="showPassword ? 'text' : 'password'"
            :append-icon="showPassword ? 'mdi-eye-off' : 'mdi-eye'"
            @click:append="() => showPassword = !showPassword"
            outlined
        />

        <v-btn
            @click="signIn"
            color="primary"
            dark
            elevation="0"
            width="100%"
            height="45px"
        >
          Authenticate
          <v-icon small style="margin-left: 5px">mdi-arrow-right</v-icon>
        </v-btn>
      </v-form>
    </v-card>
    <loading-dialog v-model="loading" message="You are being authenticated, Please wait..."/>
    <error-dialog v-model="error" :error="errorVal"/>
  </div>
</template>

<script>
import {required, email} from '@/utils/validators';
import LoadingDialog from '../../components/LoadingDialog';
import ErrorDialog from '../../components/ErrorDialog';

export default {
  name: 'SignIn',
  components: {ErrorDialog, LoadingDialog},

  data: () => ({
    error: false,
    errorVal: {},
    loading: false,
    showPassword: false,
    username: '',
    password: '',
    bg: 1
  }),
  mounted() {
    this.bgNum()
  },

  methods: {
    email,
    required,
    bgNum() {
      this.bg = Math.floor(Math.random() * 5) + 1;
    },
    async signIn() {
      if (this.$refs.form.validate()) {
        this.loading = true;
        try {
          const token = (
              await this.$axios.post('/auth/sign-in', {
                username: this.username,
                password: this.password
              })
          ).data;
          const user = (await this.$axios.get('/auth/profile', {
            headers: {authorization: 'bearer ' + token.access_token}
          })).data;
          if (user.role === 0) {
            localStorage.setItem('auth_token', token.access_token)
            localStorage.setItem('auth_user', JSON.stringify(user))
            await this.$router.push('/').then(() => this.$router.go())
          } else {
            localStorage.clear()
            this.error = true;
            this.errorVal = {
              title: 'Error while signing in',
              description: 'You need a Reputation Rooster admin account to access the content ahead.'
            };
            this.loading = false;
          }
        } catch (e) {
          this.error = true;
          this.errorVal = {
            title: 'Error while signing in',
            description: 'Email or Password incorrect!'
          };
          this.loading = false;
        }

        this.loading = false;
      }
    }
  }
};
</script>

<style lang="sass" scoped>
.sign-in
  width: 448px
  padding: 20px 40px 40px 40px
  background: white
  text-align: center
  border-radius: 8px

  &__header
    display: grid
    grid-column-gap: 20px
    grid-template-columns: calc(30% - 20px) 70%

  &__logo
    width: 100%
    margin-bottom: 10px

  &__title
    color: black
    font-size: 24px
    margin-bottom: 10px
    font-weight: normal
    font-family: google-sans, sans-serif

  &__message
    font-size: 13px
    margin-bottom: 40px

.main
  height: 100vh
  position: relative
  display: flex
  justify-content: center
  align-items: center

.background
  height: 100vh
  width: 100%
  position: absolute
  top: 0
  left: 0

.background.bg1
  background-color: #e5e5f7
  opacity: 0.5
  background: linear-gradient(135deg, #7c1e1e55 25%, transparent 25%) -22px 0/ 44px 44px, linear-gradient(225deg, #7c1e1e 25%, transparent 25%) -22px 0/ 44px 44px, linear-gradient(315deg, #7c1e1e55 25%, transparent 25%) 0px 0/ 44px 44px, linear-gradient(45deg, #7c1e1e 25%, #e5e5f7 25%) 0px 0/ 44px 44px

.background.bg2
  background-color: #e5e5f7
  opacity: 0.5
  background-image: radial-gradient(#7c1e1e 1.1px, #e5e5f7 1.1px)
  background-size: 22px 22px
  background-repeat: repeat


.background.bg3
  background-color: #e5e5f7
  opacity: 0.5
  background-image: linear-gradient(135deg, #7c1e1e 25%, transparent 25%), linear-gradient(225deg, #7c1e1e 25%, transparent 25%), linear-gradient(45deg, #7c1e1e 25%, transparent 25%), linear-gradient(315deg, #7c1e1e 25%, #e5e5f7 25%)
  background-position: 22px 0, 22px 0, 0 0, 0 0
  background-size: 22px 22px
  background-repeat: repeat

.background.bg4
  background-color: #e5e5f7
  opacity: 0.5
  background-image: linear-gradient(135deg, #7c1e1e 25%, transparent 25%), linear-gradient(225deg, #7c1e1e 25%, transparent 25%), linear-gradient(45deg, #7c1e1e 25%, transparent 25%), linear-gradient(315deg, #7c1e1e 25%, #e5e5f7 25%)
  background-position: 22px 0, 22px 0, 0 0, 0 0
  background-size: 44px 44px
  background-repeat: repeat

.background.bg5
  background-color: #e5e5f7
  opacity: 0.5
  background: repeating-linear-gradient(45deg, #7c1e1e, #7c1e1e 11px, #e5e5f7 11px, #e5e5f7 55px)

</style>

<style>
html {
  overflow-y: auto;
}
</style>

<template>
    <div>
        <v-card class="mx-auto" elevation="5" min-width="350px">
            <v-tabs
                    v-model="tab"
                    class="pa-0 mb-2"
            >
                <v-tabs-slider color="primary"></v-tabs-slider>

                <v-tab v-for="(type, index) in campaignsType" :key="index">{{ type }}</v-tab>
            </v-tabs>

            <div v-if="campaign">
                <div v-if="!loading" class="main-campaign">
                    <div class="pa-5 rounded-0">
                        <div class="d-flex justify-space-between align-center px-4">
                            <v-card-title class="pa-0">Get Reviews</v-card-title>
                        </div>
                        <div class="px-4">
                            <div class="d-flex py-4">
                                <v-avatar class="mr-3" color="primary" rounded>
                                    <v-icon color="#fff">mdi-star-outline</v-icon>
                                </v-avatar>
                                <div>
                                    <h5>New Reviews</h5>
                                    <h4 class="primary--text">{{ campaign.total_reviews }}</h4>
                                </div>
                            </div>
                            <div class="d-flex py-4">
                                <v-avatar class="mr-3" color="#11CA28" rounded>
                                    <v-icon color="#fff">mdi-account-outline</v-icon>
                                </v-avatar>
                                <div>
                                    <h5>Customers Entered</h5>
                                    <h4 class="primary--text">{{ campaign.people_entered }}</h4>
                                </div>
                            </div>
                            <div class="d-flex py-4">
                                <v-avatar class="mr-3" color="#FFDF00" rounded>
                                    <v-icon color="#fff">mdi-account-outline</v-icon>
                                </v-avatar>
                                <div>
                                    <h5>Customers Enrolled</h5>
                                    <h4 class="primary--text">{{ campaign.people_enrolled }}</h4>
                                </div>
                            </div>
                            <div class="d-flex py-4">
                                <v-avatar class="mr-3" color="#0099FF" rounded>
                                    <v-icon color="#fff">mdi-account-outline</v-icon>
                                </v-avatar>
                                <div>
                                    <h5>Customers Exited</h5>
                                    <h4 class="primary--text">{{ campaign.people_exited }}</h4>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="pa-5">
                        <v-card-title class="py-0">{{ tab === 0 ? 'Message' : 'SMS' }}  Templates</v-card-title>
                        <v-simple-table>
                            <template #default>
                                <thead v-if="$vuetify.breakpoint.mdAndUp">
                                <tr>
                                    <th class="text-left">
                                        Subject
                                    </th>
                                    <th class="text-left">
                                        Type
                                    </th>
                                    <th class="text-left">
                                        Sent
                                    </th>
                                    <th class="text-left">
                                        Opened
                                    </th>
                                    <th class="text-left">
                                        Clicked
                                    </th>
                                    <th class="text-left">
                                        Goal
                                    </th>
                                </tr>
                                </thead>
                                <tbody v-if="$vuetify.breakpoint.mdAndUp">
                                <tr
                                    v-for="(message,i) in campaign.messages"
                                    :key="i"
                                >
                                    <td class="subject">
                                        <div class="d-flex align-center justify-space-between">
                                            <p class="ma-0">{{ i + 1 }}. {{ message.subject }}</p>
                                        </div>
                                    </td>
                                    <td>{{ getType(message.type) }}</td>
                                    <td>{{ message.sent || 0 }}</td>

                                    <td>{{ message.type === 0 ? message.opened || 0 : "-" }}</td>

                                    <td>{{ message.clicked || 0 }}</td>
                                    <td>{{ message.scored || 0 }}</td>
                                </tr>
                                </tbody>
                                <tbody v-else>
                                <tr
                                    v-for="(message,i) in campaign.messages"
                                    :key="i"
                                    class="v-data-table__mobile-table-row"
                                >
                                    <td class="subject v-data-table__mobile-row">
                                        <div class="v-data-table__mobile-row__header">Subject</div>
                                        <div class="v-data-table__mobile-row__cell d-flex align-center justify-space-between">
                                            <p class="ma-0 mr-2">{{ i + 1 }}. {{ message.subject }}</p>
                                        </div>
                                    </td>
                                    <td class="v-data-table__mobile-row">
                                        <div class="v-data-table__mobile-row__header">Type</div>
                                        <div class="v-data-table__mobile-row__cell">{{ getType(message.type) }}</div>
                                    </td>
                                    <td class="v-data-table__mobile-row">
                                        <div class="v-data-table__mobile-row__header">Sent</div>
                                        <div class="v-data-table__mobile-row__cell">{{ message.sent || 0 }}</div>
                                    </td>
                                    <td class="v-data-table__mobile-row">
                                        <div class="v-data-table__mobile-row__header">Opened</div>
                                        <div class="v-data-table__mobile-row__cell">
                                            {{ message.type === 0 ? message.opened || 0 : "-" }}
                                        </div>
                                    </td>
                                    <td class="v-data-table__mobile-row">
                                        <div class="v-data-table__mobile-row__header">Clicked</div>
                                        <div class="v-data-table__mobile-row__cell">
                                            {{ message.type === 0 ? message.clicked || 0 : "-" }}
                                        </div>
                                    </td>
                                    <td class="v-data-table__mobile-row">
                                        <div class="v-data-table__mobile-row__header">Scored</div>
                                        <div class="v-data-table__mobile-row__cell">{{ message.scored || 0 }}</div>
                                    </td>
                                </tr>
                                </tbody>
                            </template>
                        </v-simple-table>
                    </div>
                </div>

                <div v-else class="d-flex justify-center align-center" style="min-height: 500px">
                    <div class="d-flex align-center">
                        <v-progress-circular class="mr-3" color="primary" indeterminate/>
                        <p class="ma-0">Loading {{ tab === 0 ? 'Email' : 'SMS' }} Requests...</p>
                    </div>
                </div>
            </div>
            <div v-else>
                <div class="d-flex justify-center align-center" style="min-height: 300px">
                    <div class="d-flex align-center">
                        <p class="ma-0">No data found!</p>
                    </div>
                </div>
            </div>



        </v-card>
        <loading-dialog v-model="loadingDialog" :message="loadingMessage"/>
    </div>
</template>

<script>
import LoadingDialog from "@/components/LoadingDialog";

export default {
    name: "CampaignsStat",
    components: {LoadingDialog},
    props: {
        emailCampaign: {
            type: Object,
            default: () => {}
        },
        smsCampaign: {
            type: Object,
            default: () => {}
        }
    },
    data() {
        return {
            loading: false,
            dialog: false,
            loadingDialog: false,
            loadingMessage: "Updating Template...",
            campaignsType: [
                'Email Requests', 'SMS Requests'
            ],
            campaign: null,
            tab: 0,
            data: {},
        };
    },
    watch: {
        tab(newVal) {
            this.loadingDialog = true;
            if(newVal === 0) this.campaign = this.emailCampaign

            if(newVal === 1) {
                    this.campaign = this.smsCampaign;
                    this.campaign.messages = this.campaign.messages.filter(message => message.type === 1);
            }
            this.loadingDialog = false;
        }
    },
    created() {
        setTimeout(() => {
            this.tab = 0;
            this.campaign = this.emailCampaign;
        }, 3000);
    },
    methods: {
        getType(type) {
            switch (type) {
                case 0:
                    return "Email";
                case 1:
                    return "SMS";
            }
        },
        getTemplateTitle(i) {
            switch (i) {
                case 0:
                    return "First Message";
                case 1:
                    return "Second Message";
                case 2:
                    return "Third Message";
                case 3:
                    return "Fourth Message";
            }
        },
    }
};
</script>

<style scoped>
.main-campaign {
    display: grid;
    grid-template-columns: 1fr;
    max-width: 100%;
    min-width: 350px;
}

.main-campaign > div {
    max-width: 100%;
}

.subject div .editMessage {
    cursor: pointer;
    padding: 5px 10px;
    border-radius: 4px;
    background-color: #e5e5e5;
}

@media screen and (min-width: 1000px) {
    .main-campaign {
        grid-template-columns: 1fr 2fr;
        gap: 15px
    }

    .main-campaign > div:first-of-type {
        border-right: 1px solid #b5b5b5;
    }

    .subject div .editMessage {
        opacity: 0;
        visibility: hidden;
        cursor: pointer;
        padding: 5px 10px;
        border-radius: 4px;
    }

    .subject div .editMessage:hover {
        background-color: #e5e5e5;
    }

    .subject:hover div .editMessage {
        opacity: 1;
        visibility: visible;
    }
}
</style>

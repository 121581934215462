var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('data-table',{attrs:{"allow-add":false,"headers":_vm.headers,"loader":_vm.loadData,"view-handler":_vm.getUser() ? _vm.getUser().scopes.includes('customers:detail') ? _vm.viewCustomer : null : null,"title":"Customers"},on:{"done":function($event){return _vm.$router.back()}},scopedSlots:_vm._u([{key:"last_name",fn:function(ref){
var item = ref.item;
return [(item.last_name)?_c('span',[_vm._v(_vm._s(item.last_name))]):_c('span',[_vm._v("-")])]}},{key:"is_verified",fn:function(ref){
var item = ref.item;
return [_c('v-checkbox',{attrs:{"readonly":""},model:{value:(item.is_verified),callback:function ($$v) {_vm.$set(item, "is_verified", $$v)},expression:"item.is_verified"}})]}},{key:"is_trial",fn:function(ref){
var item = ref.item;
return [(_vm.isTrial(item))?_c('v-checkbox',{attrs:{"readonly":""},model:{value:(_vm.checked),callback:function ($$v) {_vm.checked=$$v},expression:"checked"}}):_c('v-checkbox',{attrs:{"readonly":""},model:{value:(_vm.unchecked),callback:function ($$v) {_vm.unchecked=$$v},expression:"unchecked"}})]}},{key:"account_deleted",fn:function(ref){
var item = ref.item;
return [_c('v-checkbox',{attrs:{"readonly":""},model:{value:(item.account_deleted),callback:function ($$v) {_vm.$set(item, "account_deleted", $$v)},expression:"item.account_deleted"}})]}},{key:"extra-actions",fn:function(ref){
var item = ref.item;
return [(_vm.isTrial(item) && (_vm.getUser() && _vm.getUser().scopes.includes('customers:extend')))?_c('v-icon',{staticClass:"mr-1",staticStyle:{"padding":"5px","border-radius":"6px","background":"rgba(0,0,0,0.4)"},attrs:{"color":"white","small":""},on:{"click":function($event){return _vm.openTrialExtender(item)}}},[_vm._v(" mdi-check-circle ")]):_vm._e()]}}])}),_c('v-dialog',{attrs:{"width":"400"},model:{value:(_vm.openExtend),callback:function ($$v) {_vm.openExtend=$$v},expression:"openExtend"}},[_c('v-card',{staticStyle:{"padding":"40px"}},[_c('v-form',{ref:"extendTrial"},[_c('p',{staticClass:"span-2 form__title mb-0"},[_vm._v("Extend Trial")]),_c('p',{staticClass:"span-2 mb-6"},[_vm._v("This will set the user's status to trial till expiry date.")]),_c('v-text-field',{attrs:{"rules":[_vm.required(), _vm.dateValidation],"dense":"","label":"Expiry Date","outlined":"","type":"date"},model:{value:(_vm.extendDate),callback:function ($$v) {_vm.extendDate=$$v},expression:"extendDate"}}),_c('v-btn',{staticStyle:{"width":"100%"},attrs:{"color":"primary","elevation":"0"},on:{"click":_vm.extendTrial}},[_vm._v("Submit ")])],1)],1)],1),_c('loading-dialog',{attrs:{"message":"Loading..."},model:{value:(_vm.loadingDialog),callback:function ($$v) {_vm.loadingDialog=$$v},expression:"loadingDialog"}}),_c('ErrorDialog',{attrs:{"error":_vm.errorVal},model:{value:(_vm.error),callback:function ($$v) {_vm.error=$$v},expression:"error"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }
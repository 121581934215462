import {getUser} from "@/utils/local";
import RecentPostsView from "@/views/recent-reviews/RecentReviewsView";

const recentReviewsRoutes = [
  getUser()?.scopes?.includes('recent-reviews:view') ?
  {
    name: 'recentReviews',
    path: '/recent-reviews',
    component: RecentPostsView
  } : null,
];

export const recentReviewsRouter = recentReviewsRoutes.filter(function(x) {
  return x !== null;
})

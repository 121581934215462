<template>
  <SimpleForm :onSubmit="submit" @done="$router.back()">
    <p class="span-2 form__title">{{ isEdit ? 'Update Notification' : 'Create New Notification' }}</p>

    <div
        style="
        display: grid;
        grid-template-columns: 2fr 1fr;
        grid-column-gap: 20px;
      "
        class="span-2"
    >
      <v-text-field
          v-model="notification.title"
          dense
          :rules="[required('A title must be provided')]"
          label="Notification Title"
          outlined
      />
      <v-select
          v-model="notification.channel"
          dense
          outlined
          :rules="[required('A channel must be selected')]"
          label="Select Channel"
          :items="channels"
      />
    </div>

    <v-textarea
        v-model="notification.message"
        label="Description"
        dense
        :rules="[required('A Description must be provided')]"
        outlined
        persistent-hint
        class="span-2"
    />

    <template #actions="{ context }">
      <v-btn v-if="(getUser() && getUser().scopes.includes('notifications:test'))" class="mr-3"
             @click="openTestNotificationDialog(context)" text>Test
      </v-btn>
      <v-btn
          v-if="(getUser() && getUser().scopes.includes('notifications:send'))"
          @click="sendAndSaveNotification(context)"
          outlined
          class="mr-3"
          color="primary"
      >Send And Save
      </v-btn>
    </template>

    <loading-dialog v-model="loading" message="Fetching Notification Data"/>

    <v-dialog width="30%" v-model="openTestDialog" persistent>
      <v-card class="pa-3">
        <v-form ref="testNotification">
          <v-card-title>Test Notification</v-card-title>
          <ul class="mb-3" style="color: red" v-for="(error) of errors" :key="error">
            <li>{{ error }}</li>
          </ul>
          <div class="d-flex justify-space-between span-2">
            <h4>Recipients</h4>
            <v-btn
                elevation="0"
                color="primary"
                @click="testNotification.emails.push({email: null})"
            >
              <v-icon small>mdi-plus</v-icon>
              Add
            </v-btn>
          </div>
          <div class="span-2 my-3 py-2" style="max-height: 200px;overflow-y: auto">
            <div v-for="(email, i) of testNotification.emails" :key="i" class="span-2">
              <v-text-field v-model="email.email" :label="'Email # ' + (i + 1)"
                            :rules="[required('Email is required')]" outlined dense>
                <template v-slot:append>
                  <v-icon @click="removeEmail(i)" v-if="testNotification.emails.length > 1" color="red">mdi-delete
                  </v-icon>
                </template>
              </v-text-field>
            </div>
          </div>
          <div class="text-right pa-3">
            <v-btn @click="cancelTest" class="mr-3">Cancel</v-btn>
            <v-btn @click="submitTest" color="primary">Submit</v-btn>
          </div>
        </v-form>
      </v-card>
    </v-dialog>
  </SimpleForm>
</template>

<script>
import SimpleForm from '../../components/Form';
import LoadingDialog from '../../components/LoadingDialog';
import {NotificationsService} from '@/services/notifications-service';
import {required} from '@/utils/validators';
import {getDays, getUser} from "@/utils/local";
import moment from "moment"

export default {
  name: 'Form',
  components: {LoadingDialog, SimpleForm},

  data: () => ({
    isEdit: false,
    loading: false,
    service: new NotificationsService(),
    notification: {},
    channels: [],

    openTestDialog: false,
    searchEmail: '',
    testNotification: {
      emails: [{email: null}]
    },
    testContext: null,

    errors: [],
  }),

  mounted() {
    if(process.env.NODE_ENV === 'production') {
      this.notification.channels = 'news';
      this.channels.push('news')
    }
    else {
      this.notification.channels = 'news-staging';
      this.channels.push('news-staging')
    }
    this.loadNotification();
  },

  methods: {
    required,
    getDays,
    getUser,
    async loadNotification() {
      if (!this.$route.query.id) return;
      this.isEdit = true;
      this.loading = true;
      this.notification = await this.service.fetchOne(this.$route.query.id);
      this.loading = false;
    },
    preCheck(context) {
      if (!context.validate()) return false;
      return true;
    },
    async submit(context) {
      if (this.preCheck(context)) {
        if (this.isEdit) {
          context.changeLoadingMessage('Updating Notification');
          try {
            this.notification = await this.service.update(this.notification);
            return true
          } catch (e) {
            context.reportError({
              title: 'Error occurred while updating Notification',
              description: e.toString()
            });
            return false
          }
        } else {
          try {
            context.changeLoadingMessage('Creating A New Notification');
            this.notification = await this.service.create(this.notification);
            return true
          } catch (e) {
            context.reportError({
              title: 'Error occurred while creating Notification',
              description: e.toString()
            });
            return false
          }
        }
      }
    },

    openTestNotificationDialog(context) {
      if (this.preCheck(context, false)) {
        this.openTestDialog = true
        this.testNotification = {
          emails: [{email: null}]
        }
        this.testContext = context
      }
    },

    async sendNotification(context) {
      if (this.preCheck(context)) {
        context.changeLoadingStatus(true);
        context.changeLoadingMessage('Sending Notification to Devices');
        await this.service.send(this.notification);
        context.changeLoadingStatus(false);
      }
    },
    async sendAndSaveNotification(context) {
      if (this.preCheck(context)) {
        context.changeLoadingStatus(true);
        await this.submit(context);
        await this.sendNotification(context)

        this.$router.back()
      }
    },

    cancelTest() {
      this.openTestDialog = false
    },

    async submitTest() {
      if (this.preCheck(this.$refs.testNotification, false)) {
        this.errors = []
        this.testContext.changeLoadingStatus(true);
        this.testContext.changeLoadingMessage('Sending Test Notification');
        // console.log(this.testNotification.emails)
        const emails = this.testNotification.emails.map((email) => email.email)
        if (!emails || (emails && emails.length <= 0)) {
          this.errors.push("At least one email is required")
          this.testContext.changeLoadingStatus(false);
          return
        }
        emails.forEach((email) => {
          if (!/^\w+([\\.-]?\w+)*@\w+([\\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
            this.errors.push(email + " is invalid.")
          }
        })
        if (this.errors.length > 0) {
          this.testContext.changeLoadingStatus(false);
          return
        }

        const data = {...this.notification}

        data.emails = emails
        // console.log(data)
        try {
          await this.service.test(data)
          this.openTestDialog = false
        } catch (e) {
          this.testContext.reportError({
            title: 'Error sending test Notification',
            description: e?.response?.data?.message.toString() || 'Some Error Occured'
          });
          this.testContext.changeLoadingStatus(false);
        }
        this.testContext.changeLoadingStatus(false);
      }
    },
    formatTime(time) {
      return moment(time, "HH:mm").format("h:mm A")
    },
    removeEmail(i) {
      if (this.testNotification.emails.length > 1) {
        this.testNotification.emails.splice(i, 1)
      }
    },
  }
};
</script>

<style scoped>
p {
  font-weight: bold;
  text-align: left;
}
</style>

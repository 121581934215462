<template>
  <div>
    <data-table
        :loader="loadData"
        :headers="headers"
        :custom-filter="customFilter"
        title="Coupons"
        :allow-add="getUser() ? getUser().scopes.includes('coupons:new') : false"
        @add-new="addNew"
        @done="$router.back()"
        :delete-handler="getUser() ? getUser().scopes.includes('coupons:delete') ?  service.delete : null : null"
    >
      <template #name="{ item }">
        <span>{{ item.coupon.name }}</span>
      </template>
      <template #type="{ item }">
        <span>{{ item.coupon.percent_off ? 'Percentage (%)' : 'Amount ($)' }}</span>
      </template>
      <template #times_redeemed="{ item }">
        <span>{{ item.times_redeemed + ' times' }}</span>
      </template>
      <template #discount="{ item }">
        <span v-if="item.coupon.percent_off">{{ item.coupon.percent_off + '%' }}</span>
        <span v-else-if="item.coupon.amount_off">{{ item.coupon.amount_off / 100 + '$' }}</span>
      </template>
    </data-table>

    <loading-dialog v-model="loadingDialog" message="Sending Notification"/>
  </div>
</template>

<script>
import DataTable from '../../components/DataTable';
import LoadingDialog from "../../components/LoadingDialog";
import {getUser} from '@/utils/local';
import {CouponsService} from "@/services/coupons-service";


export default {
  components: {LoadingDialog, DataTable},

  data: () => ({
    loading: false,
    loadingDialog: false,
    service: new CouponsService(),

    headers: [
      {
        text: 'Coupon Name',
        value: 'name',
        sortable: false
      },
      {
        text: 'Promo Code',
        value: 'code',
        sortable: false
      },
      {
        text: 'Discount Type',
        value: 'type'
      },
      {
        text: 'Discount',
        value: 'discount'
      },
      {
        text: 'Redeemed',
        value: 'times_redeemed'
      },
    ]
  }),

  methods: {
    getUser,
    addNew() {
      this.$router.push('/coupon');
    },
    loadData() {
      return this.service.fetchAll();
    },
    customFilter(value, search, item) {
      return search != null && (item.code.toLowerCase().includes(search.toLowerCase()) || item.coupon.name.toLowerCase().includes(search.toLowerCase()))
    },
  }
};
</script>

<style lang="sass" scoped>
.chip
  font-weight: 500
  padding: 5px 10px
  border-radius: 50px
  background: lightgray
</style>

<template>
    <div>
        <SimpleForm :onSubmit="submit" :should-reset="false">
            <div>
                <v-btn id="refresh" class="refresh" color="primary" icon style="margin-left: 15px" @click="refresh">
                    <v-icon>mdi-refresh</v-icon>
                </v-btn>
                Refresh
            </div>
            <p class="span-2 form__title">{{ 'Find New Lead' }}</p>

            <v-row class="span-2 d-flex mt-5">
                <v-col cols="12" md="6">
                    <v-text-field
                            v-model="keyword"
                            :rules="[required('A keyword must be provided')]"
                            class="span-2"
                            dense
                            hide-details="auto"
                            label="Keyword"
                            outlined
                    />
                </v-col>

                <v-col cols="12" md="6">
                    <v-select
                            v-model="no_of_items"
                            :items="no_of_items_options"
                            :rules="[required('Number of items must be provided')]"
                            dense
                            hide-details="auto"
                            item-text="name"
                            item-value="_id"
                            label="No of items"
                            outlined
                    />
                </v-col>
            </v-row>

            <v-row class="span-2 d-flex mt-5">
                <v-col cols="12" md="6">
                    <v-autocomplete
                            v-model="country"
                            :items="countryOptions"
                            dense
                            hide-details
                            item-text="name"
                            item-value="_id"
                            label="Country"
                            outlined
                            return-object
                            @change="getStates"
                    />
                </v-col>

                <v-col cols="12" md="6">
                    <v-autocomplete
                            v-model="state"
                            :items="stateOptions"
                            dense
                            hide-details
                            item-text="name"
                            item-value="_id"
                            label="State"
                            outlined
                            return-object
                            @change="getCities"
                    />
                </v-col>
            </v-row>

            <v-row class="span-2">
                <v-col cols="12">
                    <v-autocomplete
                            id="city"
                            v-model="cities"
                            label="City"
                            multiple
                            outlined
                            class="span-2 py-2"
                            chips
                            dense
                            hide-details="auto"
                            :rules="[required()]"
                            :items="cityOptions"
                    >
                        <template v-slot:selection="data">
                            <v-chip small class="ma-1">
                                {{ data.item }}
                            </v-chip>
                        </template>
                    </v-autocomplete>

                    <!--                    <v-autocomplete-->
                    <!--                            v-model="city"-->
                    <!--                            :items="cityOptions"-->
                    <!--                            dense-->
                    <!--                            hide-details-->
                    <!--                            item-text="name"-->
                    <!--                            label="City"-->
                    <!--                            outlined-->
                    <!--                            return-object-->
                    <!--                    />-->
                </v-col>
            </v-row>

            <template #actions>
                <v-btn v-if="downloadFile" class="mr-2" @click="downloadCSV">Download Csv</v-btn>
            </template>

            <loading-dialog v-model="loading" message="Fetching Lead Data"/>
        </SimpleForm>

        <div class="history">
            <h3 class="mb-2">History</h3>
            <div v-if="showHistory">
                <div class="d-flex justify-space-between">
                    <p class="font-weight-light">result.csv</p>
                    <v-btn color="primary" elevation="0" @click="downloadCSV">
                        Download Old CSV
                    </v-btn>
                </div>
            </div>
            <p v-else class="history font-weight-light">No History found!</p>
        </div>

        <loading-dialog v-model="loading" message="Please wait ... "/>

        <!--For notification-->
        <ErrorDialog v-model="success" :error="{title: 'Business Found!',  description: 'CSV automatically downloaded in the browser!'}" />

        <!--For Errors-->
        <ErrorDialog v-model="error" :error="{title: 'Ops, Something went wrong!', description: 'Business not found'}"/>
    </div>
</template>

<script>
import SimpleForm from '../../components/Form';
import LoadingDialog from '../../components/LoadingDialog';
import {required} from '@/utils/validators';
import {BusinessLeadsService} from "@/services/business-leads-service";
import {SCHEDULER_URL} from "@/utils/local";
import ErrorDialog from "@/components/ErrorDialog.vue";

export default {
    name: 'Form',
    components: {ErrorDialog, LoadingDialog, SimpleForm},

    data: () => ({
        service: new BusinessLeadsService(),
        loading: false,

        keyword: '',
        no_of_items: '',
        country: {},
        state: '',
        cities: [],
        leads: [],
        downloadFile: '',
        showHistory: false,

        countryOptions: [],
        stateOptions: [],
        cityOptions: [],

        no_of_items_options: [
            {name: '20', _id: 20},
            {name: '40', _id: 40},
            {name: '60', _id: 60},
            {name: '80', _id: 80},
            {name: '100', _id: 100},
        ],

        error: false,
        success: false,
    }),

    async mounted() {
        this.loading = true;
        await this.getCountries();
        await this.checkHistory();
        this.resetFormState();
        this.loading = false;
    },

    methods: {
        required,
        refresh() {
            window.location.reload()
        },
        async getCountries() {
            await this.$axios
                .get('https://countriesnow.space/api/v0.1/countries/states')
                .then((res) => (this.countryOptions = res.data.data))
        },

        getStates() {
            this.stateOptions = this.country.states
        },

        async getCities() {
            const data = {
                country: this.country.name,
                state: this.state.name,
            }
            await this.$axios
                .post(
                    'https://countriesnow.space/api/v0.1/countries/state/cities',
                    data
                )
                .then((res) => {
                    this.cityOptions = res.data.data;
                    if (this.cityOptions.length > 0) {
                        this.cityOptions.unshift('All Cities');
                    }
                })
        },

        getQueryForScrapper() {
            let keywordsArray = [];
            // When city is not null and user selects All Cities option
            if (this.cities.length > 0 && this.cities.includes('All Cities')) {

                let citiesClone = this.cityOptions;

                citiesClone.shift();

                citiesClone.forEach(city => {
                    keywordsArray.push(this.keyword + ' in ' + city + ', ' + this.state.name + ', ' + this.country.name);
                })

            }
            // When city is not null and user does not select 'All Cities' option
            else if (this.cities.length > 0 && !this.cities.includes('All Cities')) {

                for (const city of this.cities) {
                    // city + state + country
                    if (city && this.state && this.country) {
                        keywordsArray.push(this.keyword + ' in ' + city + ', ' + this.state.name + ', ' + this.country.name);
                    }
                    // city + state
                    else if (city && this.state) {
                        keywordsArray.push(this.keyword + ' in ' + city + ', ' + this.state.name);
                    }
                }

            }
            // only State
            else if (this.state) {
                keywordsArray.push(this.keyword + ' in ' + this.state.name);
            }
            // only Keyword
            else {
                keywordsArray.push(this.keyword);
            }

            return keywordsArray;
        },

        async submit(context) {
            context.changeLoadingMessage('Finding Business Lead');
            if (context.validate()) {
                this.loading = true;
                try {
                    let keywordsArray = this.getQueryForScrapper();

                    // console.log('keywordsArray: ', keywordsArray);

                    // if (this.city && this.state && this.country) {
                    //     if (this.city === 'All Cities') {
                    //
                    //         let citiesClone = this.cityOptions;
                    //
                    //         citiesClone.shift();
                    //         let allCities = citiesClone;
                    //
                    //         // for (let i = 0; i < 10; i++) {
                    //         //     keywordsArray.push(this.keyword + ' in ' + allCities[i] + ', ' + this.state.name + ', ' + this.country.name);
                    //         // }
                    //         allCities.forEach(city => {
                    //             keywordsArray.push(this.keyword + ' in ' + city + ', ' + this.state.name + ', ' + this.country.name);
                    //         })
                    //
                    //     } else {
                    //         keywordsArray.push(this.keyword + ' in ' + this.city + ', ' + this.state.name + ', ' + this.country.name);
                    //     }
                    // } else if (this.city && this.state) {
                    //     keywordsArray.push(this.keyword + ' in ' + this.city + ', ' + this.state.name);
                    // } else if (this.state) {
                    //     keywordsArray.push(this.keyword + ' in ' + this.state.name);
                    // } else {
                    //     keywordsArray.push(this.keyword);
                    // }

                    for (let i = 0; i < keywordsArray.length; i++) {
                        const _data = {
                            keyword: keywordsArray[i],
                            results_to_fetch: this.no_of_items,
                        }

                        // console.log('_data: ', _data)

                        try {
                            let response = await this.service.create(_data);
                            this.leads = response.data ?? [];
                            // this.leads = ['123'];
                        } catch (e) {
                            console.log('Error: ', e)
                        }

                        if (this.leads.length > 0) {
                            await this.uploadCsvToServer(context);
                        }
                    }

                    // if no business found (Error)
                    if(this.leads.length === 0) {
                        this.error = true
                        this.success = false
                    }
                    // else business found (Success)
                    else {
                        this.error = false
                        this.success = true
                    }

                    this.loading = false;

                    return true
                } catch (e) {
                    context.reportError({
                        title: 'Error occurred while finding Lead',
                        description: e?.data?.message || 'Something went wrong',
                    });
                    this.loading = false;
                    return false
                }
            }
        },

        async uploadCsvToServer(context) {
            this.loading = true;
            const csv = this.arrayToCSV(this.leads);
            const blob = new Blob([csv], {type: 'text/csv;charset=utf-8;'});
            const csvFile = new File([blob], 'result.csv', {type: 'text/csv;charset=utf-8;'});

            try {
                let uploadFile = await this.service.uploadFile(csvFile);
                this.downloadFile = uploadFile.path;
                await this.downloadCSV();

            } catch (e) {
                context.reportError({
                    title: 'Something went wrong while uploading file',
                    description: e?.data?.message || 'Something went wrong',
                });
                this.loading = false;
                return false
            }

            this.loading = false;
        },

        async checkHistory() {
            this.loading = true;
            try {
                let res = await this.service.checkHistory();
                if (res.status === 200) {
                    this.showHistory = true;
                } else {
                    this.showHistory = false;
                }
            } catch (e) {
                console.log('e', e);
                this.showHistory = false;
                this.loading = false;
            }
            this.loading = false;
        },

        resetFormState() {
            this.no_of_items = 20;
            this.country = {
                "name": "United States",
                "iso3": "USA",
                "iso2": "US",
                "states": [{"name": "Alabama", "state_code": "AL"}, {
                    "name": "Alaska",
                    "state_code": "AK"
                }, {"name": "American Samoa", "state_code": "AS"}, {
                    "name": "Arizona",
                    "state_code": "AZ"
                }, {"name": "Arkansas", "state_code": "AR"}, {
                    "name": "Baker Island",
                    "state_code": "UM-81"
                }, {"name": "California", "state_code": "CA"}, {
                    "name": "Colorado",
                    "state_code": "CO"
                }, {"name": "Connecticut", "state_code": "CT"}, {
                    "name": "Delaware",
                    "state_code": "DE"
                }, {"name": "District of Columbia", "state_code": "DC"}, {
                    "name": "Florida",
                    "state_code": "FL"
                }, {"name": "Georgia", "state_code": "GA"}, {"name": "Guam", "state_code": "GU"}, {
                    "name": "Hawaii",
                    "state_code": "HI"
                }, {"name": "Howland Island", "state_code": "UM-84"}, {
                    "name": "Idaho",
                    "state_code": "ID"
                }, {"name": "Illinois", "state_code": "IL"}, {"name": "Indiana", "state_code": "IN"}, {
                    "name": "Iowa",
                    "state_code": "IA"
                }, {"name": "Jarvis Island", "state_code": "UM-86"}, {
                    "name": "Johnston Atoll",
                    "state_code": "UM-67"
                }, {"name": "Kansas", "state_code": "KS"}, {
                    "name": "Kentucky",
                    "state_code": "KY"
                }, {"name": "Kingman Reef", "state_code": "UM-89"}, {
                    "name": "Louisiana",
                    "state_code": "LA"
                }, {"name": "Maine", "state_code": "ME"}, {
                    "name": "Maryland",
                    "state_code": "MD"
                }, {"name": "Massachusetts", "state_code": "MA"}, {
                    "name": "Michigan",
                    "state_code": "MI"
                }, {"name": "Midway Atoll", "state_code": "UM-71"}, {
                    "name": "Minnesota",
                    "state_code": "MN"
                }, {"name": "Mississippi", "state_code": "MS"}, {
                    "name": "Missouri",
                    "state_code": "MO"
                }, {"name": "Montana", "state_code": "MT"}, {
                    "name": "Navassa Island",
                    "state_code": "UM-76"
                }, {"name": "Nebraska", "state_code": "NE"}, {
                    "name": "Nevada",
                    "state_code": "NV"
                }, {"name": "New Hampshire", "state_code": "NH"}, {
                    "name": "New Jersey",
                    "state_code": "NJ"
                }, {"name": "New Mexico", "state_code": "NM"}, {
                    "name": "New York",
                    "state_code": "NY"
                }, {"name": "North Carolina", "state_code": "NC"}, {
                    "name": "North Dakota",
                    "state_code": "ND"
                }, {"name": "Northern Mariana Islands", "state_code": "MP"}, {
                    "name": "Ohio",
                    "state_code": "OH"
                }, {"name": "Oklahoma", "state_code": "OK"}, {
                    "name": "Oregon",
                    "state_code": "OR"
                }, {"name": "Palmyra Atoll", "state_code": "UM-95"}, {
                    "name": "Pennsylvania",
                    "state_code": "PA"
                }, {"name": "Puerto Rico", "state_code": "PR"}, {
                    "name": "Rhode Island",
                    "state_code": "RI"
                }, {"name": "South Carolina", "state_code": "SC"}, {
                    "name": "South Dakota",
                    "state_code": "SD"
                }, {"name": "Tennessee", "state_code": "TN"}, {
                    "name": "Texas",
                    "state_code": "TX"
                }, {
                    "name": "United States Minor Outlying Islands",
                    "state_code": "UM"
                }, {"name": "United States Virgin Islands", "state_code": "VI"}, {
                    "name": "Utah",
                    "state_code": "UT"
                }, {"name": "Vermont", "state_code": "VT"}, {
                    "name": "Virginia",
                    "state_code": "VA"
                }, {"name": "Wake Island", "state_code": "UM-79"}, {
                    "name": "Washington",
                    "state_code": "WA"
                }, {"name": "West Virginia", "state_code": "WV"}, {
                    "name": "Wisconsin",
                    "state_code": "WI"
                }, {"name": "Wyoming", "state_code": "WY"}]
            };
            if (Object.keys(this.country).length > 0) {
                this.getStates()
            }
        },

        async downloadCSV() {
            try {
                // let response = await this.service.download();
                const link = document.createElement('a');
                link.href = SCHEDULER_URL + "/download-file";
                link.setAttribute('download', 'result.csv');
                link.style.visibility = 'hidden';
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            } catch (e) {
                console.log('e', e)
            }
        },

        arrayToCSV(data) {
            let csv = '';
            const keys = Object.keys(data[0]);
            csv += keys.join(',') + '\n';
            data.forEach(item => {
                csv += keys.map(key => `"${item[key]}"`).join(',') + '\n';
            });
            return csv;
        },
    }
};
</script>

<style scoped>
p {
    font-weight: bold;
    text-align: left;
}

.history {
    width: 600px;
//padding: 40px; margin: 0 auto; border-radius: 8px;
}
</style>

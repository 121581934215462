<template>
  <SimpleForm :onSubmit="submit" @done="reloadPage">
    <p class="span-2 form__title">Update Site Information</p>

    <div class="span-2 d-flex justify-space-between align-center">
      <p class="mt-2">Status</p>
      <v-switch v-model="site.status" />
    </div>

    <v-text-field
        v-model="site.name"
        :rules="[required('A name must be provided')]"
        :disabled="!site.status"
        dense
        label="Site Name"
        outlined
    />

    <v-text-field
        v-model="site.color"
        :disabled="!site.status"
        dense
        label="Primary Color"
        outlined
    />

    <v-text-field
        v-model="site.placeholder"
        :rules="site.status ? [required('Example Url is required, please add correct url.')] : []"
        :disabled="!site.status"
        class="span-2 mb-2"
        dense
        hint="Must be a valid site url."
        label="Site Url"
        outlined
    />

    <v-textarea
        v-model="site.additional_information"
        disabled
        readonly
        outlined
        class="span-2"
        dense
        label="Addition Information"
    ></v-textarea>

    <loading-dialog v-model="loading" message="Fetching Site Data"/>
  </SimpleForm>
</template>

<script>
import SimpleForm from '../../components/Form';
import LoadingDialog from '../../components/LoadingDialog';
import {required} from '@/utils/validators';
import {ReviewSitesService} from "../../services/review-sites-service";

export default {
  name: 'Form',
  components: {LoadingDialog, SimpleForm},

  data: () => ({
    isEdit: false,
    loading: false,
    service: new ReviewSitesService(),
    site: {
      name: '',
      color: '',
      placeholder: '',
      // validation_regix: '',
      status: false
    }
  }),

  async mounted() {
    await this.loadSite();
  },

  methods: {
    required,
    reloadPage() {
      location.reload()
      this.$router.back()
    },
    async loadSite() {
      if (!this.$route.query.id) this.$router.back();
      this.loading = true;
      this.site = await this.service.fetchOne(this.$route.query.id);
      this.loading = false;
    },
    async submit(context) {
        context.changeLoadingMessage('Updating Site');
        try {
          await this.service.update(this.site);
          this.$toast.success('Site update successfully!')
          return true
        } catch (e) {
          context.reportError({
            'title': 'Error while updating Site',
            'description': e?.response?.data?.message || 'Some Error Occurred'
          })
          return false
        }
      }
  }
};
</script>

<style scoped>
p {
  font-weight: bold;
  text-align: left;
}
</style>

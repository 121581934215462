<template>
  <div>
    <v-card v-if="platform" :style="!isConnected ? 'opacity: 0.6; border: 1px solid red;' : ''"
            class="pa-4">
      <v-list-item two-line>
        <v-list-item-avatar class="mr-3" rounded>
          <v-img
              :src="resolveSiteType(platform).icon"
              alt="logo"
              contain
              eager
              max-height="30px"
              max-width="30px"
          ></v-img>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title>{{ username }}</v-list-item-title>
          <v-list-item-subtitle>
            {{ resolveSiteType(platform).name }}
          </v-list-item-subtitle>
          <v-list-item-action-text v-if="isConnected">
            <v-icon color="success" small>
              mdi-check-circle
            </v-icon>
            Connected
          </v-list-item-action-text>
          <v-list-item-action-text v-else>
            <v-icon color="warning" small>
              mdi-alert
            </v-icon>
            Reconnection Required
          </v-list-item-action-text>
        </v-list-item-content>
      </v-list-item>
    </v-card>
  </div>
</template>

<script>
import {baseUrl} from "../main";

export default {
  name: "SiteViewer",
  props: {
    platform: {
      type: Object,
      default: () => {
      }
    },
    location: {
      type: Object,
      default: () => {
      }
    }
  },
  computed: {
    isConnected() {
      return this.platform.connected
    },
    username() {
      if (this.platform.type.type) {
        if (this.platform.type.type === 0) {
          return "Reputation Rooster";
        } else if (this.platform.type.type === 1) {
          return this.platform.data.page.name;
        } else if (this.platform.type.type === 2) {
          return this.platform.data.location.title;
        } else {
          return this.platform.data.business.name;
        }
      } else {
        return 'Reputation Rooster'
      }
    }
  },
  methods: {
    resolveSiteType(item) {
      console.log('item: ', item.type)
      if (item.type) {
        const obj = {};
        obj.icon = `${baseUrl}/uploads/${item.type.icon}`;
        obj.name = item.type.name;
        return obj;
      } else {
        return {
          icon: require('../assets/logos/logo-head.png'),
          name: 'Reputation Rooster'
        }
      }
    }
  }
}
</script>
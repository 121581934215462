<template>
  <div>
    <data-table
      :loader="loadData"
      :headers="headers"
      title="Links"
      :allow-add="false"
      @done="$router.back()"
      :delete-handler="
        getUser()
          ? getUser().scopes.includes('links:delete')
            ? service.delete
            : null
          : null
      "
      :edit-handler="null"
    >
      <template #additional-sections>
        <div
          v-if="getUser() && getUser().scopes.includes('links:create')"
          class="px-4 py-2"
          :style="$vuetify.breakpoint.mdAndUp ? 'max-width: 80%' : ''"
        >
          <h2 :style="$vuetify.breakpoint.smAndUp ? '' : 'font-size: 22px'">
            Shorten and Track Your Links
          </h2>
          <p>
            Streamlined URL shortener with click tracking for actionable
            insights. <br />
            Enter your long URL below and witness the magic of brevity!
          </p>
          <v-row
            dense
            :no-gutters="!$vuetify.breakpoint.mdAndUp"
            :style="$vuetify.breakpoint.mdAndUp ? 'max-width: 80%' : ''"
          >
            <v-col cols="12" md="10">
              <v-form ref="form" class="d-flex">
                <v-row dense :no-gutters="!$vuetify.breakpoint.mdAndUp">
                  <v-col cols="12" md="6">
                    <v-text-field
                      v-model="link.name"
                      :rules="[required()]"
                      outlined
                      dense
                      label="Title"
                    />
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-text-field
                      v-model="link.long_url"
                      :rules="[required()]"
                      outlined
                      dense
                      label="Long URL"
                    />
                  </v-col>
                </v-row>
              </v-form>
            </v-col>
            <v-col cols="12" md="2">
              <v-btn
                @click="createLink"
                color="primary"
                elevation="0"
                :width="$vuetify.breakpoint.mdAndUp ? '' : '100%'"
                >Shorten & Track!
              </v-btn>
            </v-col>
          </v-row>
        </div>
      </template>
      <template #createdAt="{ item }">
        {{ formatDate(item.createdAt) }}
      </template>

      <template #long_url="{ item }">
        <v-tooltip bottom color="primary">
          <template v-slot:activator="{ on }">
            <p v-on="on">
              {{
                item.long_url
                  ? item.long_url.length > 50
                    ? item.long_url.substring(0, 50) + '...'
                    : item.long_url
                  : ''
              }}
            </p>
          </template>
          <span>{{ item.long_url }}</span>
        </v-tooltip>
      </template>

      <template #short_url="{ item }">
        <p @click="copyCode(item.short_url)" class="ma-0 pointer primary--text">
          {{ item.short_url }}
        </p>
      </template>
    </data-table>
    <loading-dialog v-model="loadingDialog" message="Loading..." />
    <ErrorDialog v-model="error" :error="errorVal" />
  </div>
</template>

<script>
import dayjs from 'dayjs';
import { LinksService } from '@/services/links-service';
import DataTable from '../../components/DataTable';
import { getUser } from '@/utils/local';
import ErrorDialog from '@/components/ErrorDialog';
import LoadingDialog from '../../components/LoadingDialog';
import { required } from '@/utils/validators';

export default {
  components: { ErrorDialog, LoadingDialog, DataTable },

  data: () => ({
    items: [],
    loading: false,
    service: new LinksService(),

    link: {
      name: '',
      long_url: ''
    },
    loadingDialog: false,
    error: false,
    errorVal: {},

    headers: [
      {
        text: 'Title',
        value: 'name',
        sortable: false
      },
      {
        text: 'Link',
        value: 'long_url',
        sortable: false
      },
      {
        text: 'Short Link',
        value: 'short_url',
        sortable: false
      },
      {
        text: 'Clicks',
        value: 'count'
      },
      {
        width: 180,
        text: 'Created At',
        value: 'createdAt'
      }
    ]
  }),

  methods: {
    getUser,
    required,
    copyCode(data) {
      navigator.clipboard.writeText(encodeURI(data));
      this.$toast.success('Link Copied!');
    },
    formatDate(date) {
      return dayjs(date).format('D MMM YYYY - hh:mm a');
    },
    async loadData() {
      return await this.service.fetchAll();
    },
    async createLink() {
      if (this.$refs.form.validate()) {
        this.loadingDialog = true;
        this.loadingMessage = 'Shortening Link...';

        try {
          await this.service.create(this.link);
          this.$refs.form.reset();
          document.getElementById('refresh').click();
        } catch (e) {
          this.errorVal = {
            title: 'Error while shortening link',
            description: e?.response?.data?.message || 'Some Error Occurred'
          };
          this.error = true;
        }
        this.loadingDialog = false;
      }
    }
  }
};
</script>

<style scoped>
.pointer {
  cursor: pointer;
}
</style>

<template>
  <SimpleForm :onSubmit="submit" @done="reloadPage">
    <p class="span-2 form__title">{{ isEdit ? 'Update User' : 'Create New User' }}</p>
    <v-text-field
        v-model="user.first_name"
        :rules="[required('A first name must be provided')]"
        dense
        label="First Name"
        outlined
    />
    <v-text-field
        v-model="user.last_name"
        dense
        label="Last Name"
        outlined
    />
    <v-text-field
        v-model="user.username"
        :rules="[required('Email must be provided')]"
        class="span-2"
        dense
        hint="Must be a unique email"
        label="Email"
        outlined
        persistent-hint
    />
    <v-text-field
        v-if="!isEdit"
        v-model="user.password"
        :rules="[required()]"
        :type="showPassword ? 'text' : 'password'"
        dense
        label="Password"
        outlined
    />
    <v-text-field
        v-if="!isEdit"
        v-model="confirmPassword"
        :rules="[(v) => (v && v === user.password) || 'Passwords does not match']"
        :type="showPassword ? 'text' : 'password'"
        dense
        label="Confirm Password"
        outlined
    />
    <v-checkbox
        v-if="!isEdit"
        v-model="showPassword"
        label="Show Password"
        style="margin-top: -15px"
    />

    <p class="span-2">Select User Scopes</p>

    <v-treeview
        v-model="user.scopes"
        :items="items"
        class="span-2"
        selectable
        style="text-align: left"
    />

    <loading-dialog v-model="loading" message="Fetching User Data"/>
  </SimpleForm>
</template>

<script>
import SimpleForm from '../../components/Form';
import {UsersService} from '@/services/users-service';
import LoadingDialog from '../../components/LoadingDialog';
import {required} from '@/utils/validators';

export default {
  name: 'Form',
  components: {LoadingDialog, SimpleForm},

  data: () => ({
    isEdit: false,
    loading: false,
    showPassword: false,
    service: new UsersService(),
    confirmPassword: '',
    user: {
      scopes: []
    },

    items: [
      {
        id: 'customers',
        name: 'Customers',
        children: [
          {id: 'customers:view', name: 'View Customers* (Required)'},
          {id: 'customers:extend', name: 'Extend Customer Trial'},
          {id: 'customers:detail', name: 'View Customer Detail'},
        ]
      },
      {
        id: 'locations',
        name: 'Locations',
        children: [
          {id: 'locations:view', name: 'View Locations* (Required)'},
          {id: 'locations:detail', name: 'View Location Detail'},
        ]
      },
      {
        id: 'request-stats',
        name: 'Request Stats',
        children: [
          {id: 'request-stats:view', name: 'View Request Stats* (Required)'},
        ]
      },
      {
        id: 'recent-reviews',
        name: 'Recent Reviews',
        children: [
          {id: 'recent-reviews:view', name: 'View Recent Reviews* (Required)'},
        ]
      },
      {
        id: 'review-sites',
        name: 'Review Sites',
        children: [
          {id: 'review-sites:view', name: 'View Review Sites* (Required)'},
          {id: 'review-sites:edit', name: 'Edit Review Sites'},
        ]
      },
      {
        id: 'coupons',
        name: 'Coupons',
        children: [
          {id: 'coupons:view', name: 'View Coupons* (Required)'},
          {id: 'coupons:new', name: 'Create Coupons'},
          {id: 'coupons:delete', name: 'Delete Coupons'}
        ]
      },
      {
        id: 'notifications',
        name: 'Notifications',
        children: [
          {id: 'notifications:view', name: 'View Notifications* (Required)'},
          {id: 'notifications:new', name: 'Create Notifications'},
          {id: 'notifications:send', name: 'Send Notifications'},
          {id: 'notifications:test', name: 'Test Notification'},
          {id: 'notifications:edit', name: 'Edit Notifications'},
          {id: 'notifications:delete', name: 'Delete Notifications'}
        ]
      },
      {
        id: 'users',
        name: 'Users',
        children: [
          {id: 'users:view', name: 'View  Users* (Required)'},
          {id: 'users:new', name: 'Create  Users'},
          {id: 'users:edit', name: 'Edit  Users'},
          {id: 'users:delete', name: 'Delete  Users'}
        ]
      },
      {
        id: 'business-lead',
        name: 'Business Lead',
        children: [
          {id: 'business-leads:view', name: 'View Business Lead* (Required)'},
          {id: 'business-lead:create', name: 'Create Business Lead'},
          {id: 'business-lead:export', name: ' Export Business Lead'},
        ]
      },
      {
        id: 'dashboard',
        name: 'Dashboard',
        children: [
          {id: 'dashboard:view', name: 'View Dashboard Stats* (Required)'},
        ]
      },
      {
        id: 'links',
        name: 'Links',
        children: [
          {id: 'links:view', name: 'View Links'},
          {id: 'links:create', name: 'Create Links'},
          {id: 'links:delete', name: 'Delete Links'},
        ]
      },
    ]
  }),

  mounted() {
    this.loadUser();
  },

  methods: {
    required,
    reloadPage() {
      location.reload()
      this.$router.back()
    },
    async loadUser() {
      if (!this.$route.query.id) return;
      this.isEdit = true;
      this.loading = true;
      this.user = await this.service.fetchOne(this.$route.query.id);
      this.loading = false;
    },
    async submit(context) {
      this.user.role = 0
      if (!this.user.last_name)
        this.user.last_name = ''
      if (this.isEdit) {
        context.changeLoadingMessage('Updating User');
        try {
          await this.service.update(this.user);
          const token = localStorage.getItem('auth_token');
          if (token) {
            const user = (await this.$axios.get('/auth/profile')).data;
            localStorage.setItem('auth_user', JSON.stringify(user))
          }

          return true
        } catch (e) {
          context.reportError({
            'title': 'Error while updating User',
            'description': e?.response?.data?.message || 'Some Error Occured'
          })

          return false
        }
      } else {
        context.changeLoadingMessage('Creating A New User');
        try {
          await this.service.create(this.user);
          return true
        } catch (e) {
          context.reportError({
            'title': 'Error while creating User',
            'description': e?.response?.data?.message || 'Some Error Occured'
          })

          return false
        }
      }
    }
  }
};
</script>

<style scoped>
p {
  font-weight: bold;
  text-align: left;
}
</style>

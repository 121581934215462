<template>
  <div>
    <!--        :edit-handler="getUser() && getUser().scopes.includes('review-sites:edit') ? edit : null"-->
    <data-table
        :key="dataTableKey"
        :allow-add="false"
        :edit-handler="null"
        :headers="headers"
        :loader="loadData"
        title="Review Sites"
        @done="$router.back()"
    >
      <template #icon="{item}">
        <v-img
            :src="`${url}/uploads${item.icon}`"
            alt="logo"
            contain
            eager
            max-height="30px"
            max-width="30px"
        ></v-img>
      </template>
      <template #placeholder="{item}">
        <p v-if="item.placeholder"> {{ item.placeholder }} </p>
        <span v-else>--</span>
      </template>
      <template #color="{ item }">
        <div :style="{ backgroundColor: item.color }" style="margin-left: 10px; height: 20px; width: 20px"></div>
      </template>
      <template #status="{ item }">
        <v-icon :color="item.status ? 'green' : 'red'">
          {{ item.status ? 'mdi-check' : 'mdi-close' }}
        </v-icon>
      </template>
      <template #updatedAt="{item}">
        {{ formatDate2(item.updatedAt, 'MM/DD/YYYY hh:mm a') }}
      </template>
    </data-table>
    <v-dialog v-model="reviewDialog" width="50%">
      <v-card v-if="review" class="px-6 py-4">
        <h3 class="mb-2">Review Detail</h3>
        <div>
          <h5 class="mb-2">Rating</h5>
          <v-rating :value="review.review.rating" background-color="amber" class="mb-2" color="amber" dense></v-rating>
          <h5 v-if="review.review.description">Review</h5>
          <p>{{ review.review.description }}</p>
          <h5>Reviewer Name</h5>
          <p>{{ review.review.reviewer_name }}</p>
        </div>
        <h3 class="mb-2">Company Detail</h3>
        <div>
          <h5>Name</h5>
          <p>{{ review.company_id.name }}</p>
          <h5>Email</h5>
          <p>{{ review.company_id.email }}</p>
          <h5>Phone</h5>
          <p>{{ review.company_id.phone }}</p>
        </div>
      </v-card>
    </v-dialog>
    <loading-dialog v-model="loadingDialog" message="Loading..."/>
    <ErrorDialog v-model="error" :error="errorVal"/>
  </div>
</template>

<script>
import DataTable from '../../components/DataTable';
import LoadingDialog from "../../components/LoadingDialog";
import {formatDate2, getUser} from '@/utils/local';
import ErrorDialog from "@/components/ErrorDialog";
import {ReviewSitesService} from "../../services/review-sites-service";
import {baseUrl} from "../../main";

export default {
  components: {ErrorDialog, LoadingDialog, DataTable},

  data: () => ({
    loading: false,
    loadingDialog: false,
    service: new ReviewSitesService(),
    dataTableKey: 0,
    error: false,
    errorVal: {},
    reviewDialog: false,
    review: null,
    url: baseUrl,
    headers: [
      {
        text: 'Status',
        value: 'status'
      },
      {
        text: 'Icon',
        value: 'icon',
        sortable: false
      },
      {
        text: 'Name',
        value: 'name',
        sortable: true
      },
      {
        text: 'Color',
        value: 'color'
      },
      {
        text: 'Url',
        value: 'placeholder',
        width: '200px',
      },
      {
        text: 'Updated At',
        value: 'updatedAt'
      },
    ]
  }),

  methods: {
    getUser,
    formatDate2,
    edit(item) {
      this.$router.push(`/review-site?id=${item._id}`);
    },
    loadData() {
      return this.service.fetchAll();
    },
    openReview(item) {
      this.reviewDialog = true
      this.review = item
    },
  }
};
</script>

<style lang="sass" scoped>
.chip
  font-weight: 500
  padding: 5px 10px
  border-radius: 50px
  background: lightgray
</style>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('data-table',{key:_vm.dataTableKey,attrs:{"allow-add":false,"edit-handler":null,"headers":_vm.headers,"loader":_vm.loadData,"title":"Review Sites"},on:{"done":function($event){return _vm.$router.back()}},scopedSlots:_vm._u([{key:"icon",fn:function(ref){
var item = ref.item;
return [_c('v-img',{attrs:{"src":(_vm.url + "/uploads" + (item.icon)),"alt":"logo","contain":"","eager":"","max-height":"30px","max-width":"30px"}})]}},{key:"placeholder",fn:function(ref){
var item = ref.item;
return [(item.placeholder)?_c('p',[_vm._v(" "+_vm._s(item.placeholder)+" ")]):_c('span',[_vm._v("--")])]}},{key:"color",fn:function(ref){
var item = ref.item;
return [_c('div',{staticStyle:{"margin-left":"10px","height":"20px","width":"20px"},style:({ backgroundColor: item.color })})]}},{key:"status",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{attrs:{"color":item.status ? 'green' : 'red'}},[_vm._v(" "+_vm._s(item.status ? 'mdi-check' : 'mdi-close')+" ")])]}},{key:"updatedAt",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate2(item.updatedAt, 'MM/DD/YYYY hh:mm a'))+" ")]}}])}),_c('v-dialog',{attrs:{"width":"50%"},model:{value:(_vm.reviewDialog),callback:function ($$v) {_vm.reviewDialog=$$v},expression:"reviewDialog"}},[(_vm.review)?_c('v-card',{staticClass:"px-6 py-4"},[_c('h3',{staticClass:"mb-2"},[_vm._v("Review Detail")]),_c('div',[_c('h5',{staticClass:"mb-2"},[_vm._v("Rating")]),_c('v-rating',{staticClass:"mb-2",attrs:{"value":_vm.review.review.rating,"background-color":"amber","color":"amber","dense":""}}),(_vm.review.review.description)?_c('h5',[_vm._v("Review")]):_vm._e(),_c('p',[_vm._v(_vm._s(_vm.review.review.description))]),_c('h5',[_vm._v("Reviewer Name")]),_c('p',[_vm._v(_vm._s(_vm.review.review.reviewer_name))])],1),_c('h3',{staticClass:"mb-2"},[_vm._v("Company Detail")]),_c('div',[_c('h5',[_vm._v("Name")]),_c('p',[_vm._v(_vm._s(_vm.review.company_id.name))]),_c('h5',[_vm._v("Email")]),_c('p',[_vm._v(_vm._s(_vm.review.company_id.email))]),_c('h5',[_vm._v("Phone")]),_c('p',[_vm._v(_vm._s(_vm.review.company_id.phone))])])]):_vm._e()],1),_c('loading-dialog',{attrs:{"message":"Loading..."},model:{value:(_vm.loadingDialog),callback:function ($$v) {_vm.loadingDialog=$$v},expression:"loadingDialog"}}),_c('ErrorDialog',{attrs:{"error":_vm.errorVal},model:{value:(_vm.error),callback:function ($$v) {_vm.error=$$v},expression:"error"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }
import axios from 'axios';

export class LinksService {
    async fetchAll() {
        return (await axios.get('/sys-link-shortener')).data;
    }

    async create(link) {
        return (await axios.post('/sys-link-shortener', link)).data;
    }
    async delete(link) {
        return (await axios.delete('/sys-link-shortener/' + link._id)).data;
    }
}

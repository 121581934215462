import axios from 'axios';

export class LocationsService {
    async fetchAll() {
        return (await axios.get('/company')).data;
    }

    async fetchOne(id) {
        return (await axios.get('/company/' + id)).data;
    }


    async getEmailCampaign(id) {
        return (await axios.get('/campaign/company/' + id)).data;
    }

    async getSmsCampaign(id) {
        return (
            await axios.get('/campaign/company/' + id + '?type=1')
        ).data;
    }
    async getStats(id) {
        return (await axios.get('/dashboard/' + id)).data;
    }
}

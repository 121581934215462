<template>
  <div>
    <data-table
        :allow-add="false"
        :headers="headers"
        :loader="loadData"
        :view-handler="getUser() ? getUser().scopes.includes('customers:detail') ? viewCustomer : null : null"
        title="Customers"
        @done="$router.back()"
    >
      <template #last_name="{ item }">
        <span v-if="item.last_name">{{ item.last_name }}</span>
        <span v-else>-</span>
      </template>
      <template #is_verified="{ item }">
        <v-checkbox v-model="item.is_verified" readonly />
      </template>
      <template #is_trial="{ item }">
        <v-checkbox v-if="isTrial(item)" v-model="checked" readonly/>
        <v-checkbox v-else v-model="unchecked" readonly/>
      </template>
      <template #account_deleted="{ item }">
        <v-checkbox v-model="item.account_deleted" readonly/>
      </template>
      <template #extra-actions="{ item }">
        <v-icon v-if="isTrial(item) && (getUser() && getUser().scopes.includes('customers:extend'))" class="mr-1"
                color="white" small style="padding: 5px; border-radius: 6px; background: rgba(0,0,0,0.4)"
                @click="openTrialExtender(item)"
        >
          mdi-check-circle
        </v-icon>
      </template>
    </data-table>
    <v-dialog v-model="openExtend" width="400">
      <v-card style="padding: 40px">
        <v-form ref="extendTrial">
          <p class="span-2 form__title mb-0">Extend Trial</p>
          <p class="span-2 mb-6">This will set the user's status to trial till expiry date.</p>
          <v-text-field v-model="extendDate" :rules="[required(), dateValidation]" dense label="Expiry Date" outlined
                        type="date"/>
          <v-btn
              color="primary"
              elevation="0"
              style="width: 100%"
              @click="extendTrial"
          >Submit
          </v-btn>
        </v-form>
      </v-card>
    </v-dialog>
    <loading-dialog v-model="loadingDialog" message="Loading..."/>
    <ErrorDialog v-model="error" :error="errorVal"/>
  </div>
</template>

<script>
import moment from 'moment'
import DataTable from '../../components/DataTable';
import LoadingDialog from "../../components/LoadingDialog";
import {getUser} from '@/utils/local';
import {CustomersService} from "@/services/customers-service";
import {dateValidation, required} from "@/utils/validators";
import ErrorDialog from "@/components/ErrorDialog";

export default {
  components: {ErrorDialog, LoadingDialog, DataTable},

  data: () => ({
    loading: false,
    loadingDialog: false,
    service: new CustomersService(),

    openExtend: false,
    extendDate: null,
    user: null,
    error: false,
    errorVal: {},
    checked: true,
    unchecked: false,
    headers: [
      {
        text: 'First Name',
        value: 'first_name',
        sortable: false
      },
      {
        text: 'Last Name',
        value: 'last_name',
        sortable: false
      },
      {
        text: 'Email',
        value: 'username',
        sortable: false
      },
      {
        text: 'Phone',
        value: 'phone'
      },
      {
        text: 'Is Verified',
        value: 'is_verified'
      },
      {
        text: 'Is Trailing',
        value: 'is_trial'
      },
      {
        text:'Account Deleted',
        value: 'account_deleted'
      }
    ]
  }),

  methods: {
    getUser,
    required,
    dateValidation,
    isTrial(customer) {
      console.log('customer: ', customer);
      return (customer.subscription && customer.subscription.status === "trialing");
    },
    viewCustomer(item) {
      this.$router.push('/customer?id=' + item._id);
    },
    loadData() {
      return this.service.fetchAll();
    },
    openTrialExtender(item) {
      this.user = item
      this.openExtend = true
    },
    async extendTrial() {
      if (this.$refs.extendTrial.validate()) {
        // console.log(this.user)
        this.loadingDialog = true
        let data = {
          customer: this.user.payment.id,
          date: moment(this.extendDate).format('YYYY-MM-DDT00:00')
        }

        try {
          await this.service.extendTrial(data)
          this.openExtend = false
          this.extendDate = null
          this.user = null
          this.$refs.extendTrial.reset()
          document.getElementById('refresh').click()
        } catch (e) {
          console.log(e)
          this.errorVal = {
            title: 'Error',
            description: e?.response?.data?.message || 'Some Error Occured'
          }
          this.error = true
        }

        this.loadingDialog = false
      }
    },
  }
};
</script>

<style lang="sass" scoped>
.chip
  font-weight: 500
  padding: 5px 10px
  border-radius: 50px
  background: lightgray
</style>

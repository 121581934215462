<template>
  <div>
    <v-card :style="!isConnected ? 'opacity: 0.6; border: 1px solid red;' : ''" class="pa-4"
            v-if="platform">
      <v-list-item two-line>
        <v-list-item-avatar class="mr-3" rounded>
          <v-img
              :src="icon"
              max-height="30px"
              max-width="30px"
              alt="logo"
              contain
              eager
          ></v-img>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title>{{ username }}</v-list-item-title>
          <v-list-item-subtitle>
            {{ title }}
          </v-list-item-subtitle>
          <v-list-item-action-text v-if="isConnected">
            <v-icon small color="success">
              mdi-check-circle
            </v-icon>
            Connected
          </v-list-item-action-text>
          <v-list-item-action-text v-else>
            <v-icon small color="warning">
              mdi-alert
            </v-icon>
            Reconnection Required
          </v-list-item-action-text>
        </v-list-item-content>
      </v-list-item>
    </v-card>
  </div>
</template>

<script>
export default {
  name: "PlatformViewer",
  props: {
    platform: {
      type: Object,
      default: () => {
      }
    },
    location: {
      type: Object,
      default: () => {
      }
    }
  },
  computed: {
    isConnected() {
      return this.platform.connected
    },
    title() {
      switch (this.platform.type) {
        case 0:
          return "Facebook";
        case 1:
          return "Google";
        case 2:
          return "Instagram";
        case 3:
          return "LinkedIn";
        case 4:
          return "Twitter";
      }
      return 'Reputation Rooster'
    },
    icon() {
      switch (this.platform.type) {
        case 0:
          return require('../assets/logos/facebook.png');
        case 1:
          return require('../assets/logos/google.png');
        case 2:
          return require('../assets/logos/instagram.png');
        case 3:
          return require('../assets/logos/linkedin.png');
        case 4:
          return require('../assets/logos/twitter.png');
      }
      return require('../assets/logos/logo-head.png')
    },
    username() {
      switch (this.platform.type) {
        case 0:
          return this.platform.data.page.name;
        case 1:
          return this.platform.data.location.title;
        case 2:
          return this.platform.data.page.name;
        case 3:
          return this.platform.data.name;
        case 4:
          return this.platform.data.screen_name;
      }
      return 'Reputation Rooster'
    }
  },
  methods: {
    getReviewSiteType(site) {
      switch (site) {
        case 0:
          return "Facebook";
        case 1:
          return "Google";
        case 2:
          return "Instagram";
        case 3:
          return "LinkedIn";
        case 4:
          return "Twitter";
      }
    }
  }
}
</script>

<style scoped>

</style>
import axios from 'axios';

export class UsersService {
    async fetchAll() {
        const token = localStorage.getItem('auth_token');
        let user
        if (token) {
            user = JSON.parse(localStorage.getItem('auth_user'));
        }
        return (await axios.get('/persons/admins')).data.filter((o) => o._id !== user._id);
        // return (await axios.get('/persons/admins')).data;
    }

    async fetchOne(id) {
        return (await axios.get('/persons/' + id)).data;
    }

    async update(user) {
        return (await axios.patch('/persons/' + user._id, user)).data;
    }

    async changePassword(data) {
        return (await axios.patch('/persons/change-password', data)).data;
    }

    async create(user) {
        return (await axios.post('/persons/create-admin', user)).data;
    }

    async delete(user) {
        return (await axios.delete('/persons/' + user._id)).data;
    }
}

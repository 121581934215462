import axios from 'axios';

export class CustomersService {
  async fetchAll() {
    return (await axios.get('/persons/customers')).data;
  }

  async fetchOne(id) {
    return (await axios.get('/persons/' + id)).data;
  }

  async update(id, payload) {
    return (await axios.patch('/persons/' + id, payload)).data;
  }

  async getStats(id) {
    return (await axios.get('/dashboard/person-stats/' + id)).data;
  }

  async getEmailCampaign(id) {
    return (await axios.get('/dashboard/person-campaigns-stats/' + id)).data;
  }

  async getSmsCampaign(id) {
    return (
      await axios.get('/dashboard/person-campaigns-stats/' + id + '?type=1')
    ).data;
  }

  async getProduct(id) {
    return (await axios.get('/stripe/product/' + id)).data;
  }

  async fetchLocations(id) {
    return (await axios.get('/company/person/' + id)).data;
  }
  async extendTrial(data) {
    return (await axios.patch('/stripe/extend-trial/', data)).data;
  }
  async updatePassword(id, password) {
    return (
      await axios.patch('/persons/update-customer-password/' + id, password)
    ).data;
  }

  async cancelSubscription(data) {
    return (await axios.patch("stripe/cancel-subscription/" + data.subscription_id, data)).data;
  }
}

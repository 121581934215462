import axios from 'axios';

export class ReviewSitesService {
    async fetchAll() {
        return (await axios.get('/sites')).data;
    }

    async fetchOne(id) {
        return (await axios.get('/sites/' + id)).data;
    }

    async update(site) {
        return (await axios.patch('/sites/' + site._id, site)).data;
    }

    async disable(id, status) {
        return (await axios.patch('/sites/' + id + '/status', status)).data;
    }
}

import LocationsView from "@/views/locations/LocationsView";
import LocationDetail from "@/views/locations/LocationDetail";
import {getUser} from "@/utils/local";

const locationsRoutes = [
    getUser()?.scopes.includes('locations:view') ?
    {
        name: 'Locations',
        path: '/locations',
        component: LocationsView
    } : null,
    getUser()?.scopes.includes('locations:detail') ?
    {
        name: 'Location',
        path: '/location',
        component: LocationDetail
    } : null,
];

export const locationsRouter = locationsRoutes.filter(function (x) {
    return x !== null;
})

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('data-table',{attrs:{"loader":_vm.loadData,"headers":_vm.headers,"custom-filter":_vm.customFilter,"title":"Coupons","allow-add":_vm.getUser() ? _vm.getUser().scopes.includes('coupons:new') : false,"delete-handler":_vm.getUser() ? _vm.getUser().scopes.includes('coupons:delete') ?  _vm.service.delete : null : null},on:{"add-new":_vm.addNew,"done":function($event){return _vm.$router.back()}},scopedSlots:_vm._u([{key:"name",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.coupon.name))])]}},{key:"type",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.coupon.percent_off ? 'Percentage (%)' : 'Amount ($)'))])]}},{key:"times_redeemed",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.times_redeemed + ' times'))])]}},{key:"discount",fn:function(ref){
var item = ref.item;
return [(item.coupon.percent_off)?_c('span',[_vm._v(_vm._s(item.coupon.percent_off + '%'))]):(item.coupon.amount_off)?_c('span',[_vm._v(_vm._s(item.coupon.amount_off / 100 + '$'))]):_vm._e()]}}])}),_c('loading-dialog',{attrs:{"message":"Sending Notification"},model:{value:(_vm.loadingDialog),callback:function ($$v) {_vm.loadingDialog=$$v},expression:"loadingDialog"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }
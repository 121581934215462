import moment from "moment";

export const oneSignalApiKey = 'ZmJmYTFlZDYtNjI5NC00YWI0LTllNmQtM2Q4ZWMxZWU2ZmZm'

// console.log(process.env.VUE_APP_SCHEDULER_URL)
export const SCHEDULER_URL = process.env.VUE_APP_SCHEDULER_URL;

export function getUser() {
    const token = localStorage.getItem('auth_token');
    return token ? JSON.parse(localStorage.getItem('auth_user')) : null;
}

export function getDays(item) {
    switch (item) {
        case 0:
            return 'Sunday'
        case 1:
            return 'Monday'
        case 2:
            return 'Tuesday'
        case 3:
            return 'Wednesday'
        case 4:
            return 'Thursday'
        case 5:
            return 'Friday'
        case 6:
            return 'Saturday'
        default:
            return 'Invalid Day'
    }
}

export function formatDate(date, format = 'MM/DD/YYYY') {
    const newDate = new Date(0)
    newDate.setUTCSeconds(date)
    return moment(newDate).format(format)
}

export function formatDate2(date, format = 'MM/DD/YYYY') {
    return moment(date).format(format)
}

export function resolveInitial(item) {
    if (item) {
        const names = item.split(" ");
        let initials = "";
        for (let i = 0; i < names.length; i++) {
            if (i < 2 && names[i] !== "") {
                initials += names[i][0];
            }
        }
        return initials.toUpperCase();
    } else {
        return "--";
    }
}

export function formatAddress(company) {
    const addressArr1 = []
    if (company.address) {
        if (company.address.address) {
            addressArr1.push(company.address.address)
        }
        if (company.address.unit) {
            addressArr1.push(company.address.unit)
        }
        if (company.address.city) {
            addressArr1.push(company.address.city)
        }
        if (company.address.state) {
            addressArr1.push(company.address.state)
        }
        if (company.address.zip_code) {
            addressArr1.push(company.address.zip_code)
        }
        if (company.address.country) {
            addressArr1.push(company.address.country)
        }
    }

    return addressArr1.join(', ')
}
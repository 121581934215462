import Vue from 'vue';
import VueRouter from 'vue-router';
import SignInView from './views/auth/SignIn';
import NotFound from './views/404.vue';
import Dashboard from './components/Dashboard';
import Home from './views/home.vue'
import Settings from "@/views/auth/Settings";
import {getUser} from "@/utils/local";

import {usersRouter} from './views/users/router';
import {notificationsRouter} from './views/notifications/router';
import {couponsRouter} from './views/coupons/router';
import {customersRouter} from "@/views/customers/router";
import {locationsRouter} from "@/views/locations/router";
import {requestStatsRouter} from "@/views/request-stats/router";
import {recentReviewsRouter} from "@/views/recent-reviews/router";
import {BusinessLeadsRouter} from "./views/business-leads/router";
import {linksRouter} from "@/views/links/router";
import {reviewSitesRouter} from "./views/review-sites/router";

Vue.use(VueRouter);

const routes = [
    {
        path: '/',
        component: Dashboard,
        children: [
            {
                path: '/',
                component: Home
            },
            {
                path: '/auth/settings',
                component: Settings
            },
            ...usersRouter,
            ...notificationsRouter,
            ...couponsRouter,
            ...BusinessLeadsRouter,
            ...customersRouter,
            ...locationsRouter,
            ...requestStatsRouter,
            ...recentReviewsRouter,
            ...reviewSitesRouter,
            ...linksRouter
        ]
    },
    {
        name: 'SignIn',
        path: '/auth/sign-in',
        component: SignInView
    },
    {
        name: 'NotFound',
        path: '**',
        component: NotFound
    }
];

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
});

// eslint-disable-next-line no-unused-vars
router.beforeEach((to, __, next) => {
    if (!localStorage.getItem('auth_token')) {
        if (to.path !== '/auth/sign-in') {
            next('/auth/sign-in');
        }
    }

    if(to.path === '/') {
        if(getUser() && getUser()?.scopes?.includes('dashboard:view')) {
            next();
        } else {
            const loggedInUserScoped = getUser()?.scopes;
            let redirectedToRoute;
            // console.log('loggedInUserScoped: ', loggedInUserScoped)
            if(loggedInUserScoped && loggedInUserScoped.length > 0) {
                const firstViewScope = loggedInUserScoped.filter(scope => scope.includes(':view'))[0];
                // console.log('firstViewScope: ', firstViewScope)
                if(firstViewScope) {
                    redirectedToRoute = firstViewScope.split(':')[0];
                }
                // console.log('redirectTo: ', redirectedToRoute)
                next(`/${redirectedToRoute}`)
            }
        }
    }

    next();
});

export default router;

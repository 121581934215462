<template>
  <SimpleForm :onSubmit="submit" @done="$router.back()">
    <p class="span-2 form__title">{{ isEdit ? 'Update Coupon' : 'Create New Coupon' }}</p>
    <v-text-field
        v-model="coupon.name"
        dense
        :rules="[required('A name must be provided')]"
        label="Coupon Name"
        outlined
        class="span-2"
    />
    <v-text-field
        v-model="coupon.promo_code"
        dense
        :rules="[required('A promo code must be provided')]"
        label="Coupon Promo Code"
        outlined
        class="span-2"
    />
    <v-text-field
        v-model="coupon.discount"
        dense
        :rules="[coupon.type === 1 ? requiredPercentage() : required('A discount must be provided')]"
        label="Discount"
        outlined
        type="number"
    />
    <v-select
        v-model="coupon.type"
        dense
        outlined
        :rules="[required('A discount type must be selected')]"
        label="Discount Type"
        :items="discounts"
    />
    <v-text-field
        v-model="coupon.max_redemptions"
        dense
        :rules="[coupon.max_redemptions ? requiredV3 : true]"
        label="Max Redemptions (Optional)"
        hint="Will have no restrictions if left empty."
        persistent-hint
        type="number"
        class="span-2"
        outlined
    />
    <v-select
        v-model="coupon.duration"
        dense
        outlined
        :rules="[required('A duration must be selected')]"
        label="Coupon Duration"
        :items="durations"
        :class="{'span-2': coupon.duration !== 'repeating' }"
    />
    <v-text-field
        v-if="coupon.duration === 'repeating'"
        v-model="coupon.durationInMonths"
        dense
        :rules="[requiredV3]"
        label="Duration in months"
        type="number"
        outlined
    />

    <loading-dialog v-model="loading" message="Fetching Coupon Data"/>

  </SimpleForm>
</template>

<script>
import SimpleForm from '../../components/Form';
import LoadingDialog from '../../components/LoadingDialog';
import {required, requiredV3, requiredPercentage} from '@/utils/validators';
import {getDays} from "@/utils/local";
import moment from "moment"
import {CouponsService} from "@/services/coupons-service";

export default {
  name: 'Form',
  components: {LoadingDialog, SimpleForm},

  data: () => ({
    isEdit: false,
    loading: false,
    service: new CouponsService(),
    coupon: {
      type: 1,
      duration: 'once',
    },
    discounts: [{text: 'Percentage (%)', value: 1}, {text: 'Amount ($)', value: 2}],
    durations: [
        {text: 'One Time', value: 'once'},
        {text: 'Lifetime', value: 'forever'},
        {text: 'Repeating', value: 'repeating'}
    ],
    errors: [],
  }),

  mounted() {
    this.loadCoupon();
  },

  methods: {
    required,
    requiredV3,
    requiredPercentage,
    getDays,
    async loadCoupon() {
      if (!this.$route.query.id) return;
      this.isEdit = true;
      this.loading = true;
      this.coupon = await this.service.fetchOne(this.$route.query.id);
      this.loading = false;
    },
    preCheck(context) {
      return context.validate();
    },
    async submit(context) {
      if (this.preCheck(context)) {
        try {
          this.coupon.discount = +this.coupon.discount
          context.changeLoadingMessage('Creating A New Coupon');
          if (this.coupon.max_redemptions) this.coupon.max_redemptions = +this.coupon.max_redemptions;
          else delete this.coupon.max_redemptions;
          if (this.coupon.durationInMonths) this.coupon.durationInMonths = +this.coupon?.durationInMonths;
          await this.service.create(this.coupon);
          return true
        } catch (e) {
          context.reportError({
            title: 'Failed to create coupon',
            description: e.data.message
          });
          return false
        }
      }
    },
    formatTime(time) {
      return moment(time, "HH:mm").format("h:mm A")
    }
  }
};
</script>

<style scoped>
p {
  font-weight: bold;
  text-align: left;
}
</style>

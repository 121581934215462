import axios from 'axios';

export class SitesService {
    async fetchAllReviewSites(id) {
        return (await axios.get('/review-sites/company/' + id)).data;
    }
    async fetchAllPlatforms(id) {
        return (await axios.get('/social-platforms/company/' + id)).data;
    }
}

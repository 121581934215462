<template>
  <div>
    <data-table
        :loader="loadData"
        :headers="headers"
        title="Notifications"
        :allow-add="getUser() ? getUser().scopes.includes('notifications:new') : false"
        @add-new="addNew"
        @done="$router.back()"
        :delete-handler="getUser() ? getUser().scopes.includes('notifications:delete') ?  service.delete : null : null"
        :edit-handler="getUser() ? getUser().scopes.includes('notifications:edit') ? edit : null : null"
    >
      <template #extra-actions="{ item }">
        <v-icon v-if="(getUser() && getUser().scopes.includes('notifications:send'))" small class="mr-1" @click="sendNotification(item)" color="white"
                style="padding: 5px; border-radius: 6px; background: rgba(0,0,0,0.4)"
        >mdi-send
        </v-icon>
      </template>
      <template #channel="{ item }">
        <span class="chip">{{ item.channel }}</span>
      </template>
    </data-table>

    <loading-dialog v-model="loadingDialog" message="Sending Notification"/>
    <ErrorDialog v-model="error" :error="errorVal"/>
  </div>
</template>

<script>
import DataTable from '../../components/DataTable';
import {NotificationsService} from '@/services/notifications-service';
import LoadingDialog from "../../components/LoadingDialog";
import {getUser} from '@/utils/local';
import ErrorDialog from "@/components/ErrorDialog";


export default {
  components: {ErrorDialog, LoadingDialog, DataTable},

  data: () => ({
    loading: false,
    loadingDialog: false,
    service: new NotificationsService(),

    error: false,
    errorVal: {},

    headers: [
      {
        text: 'Notification Title',
        value: 'title',
        sortable: false
      },
      {
        text: 'Description',
        value: 'message',
        sortable: false
      },
      {
        text: 'Channel',
        value: 'channel'
      },
    ]
  }),

  methods: {
    getUser,
    edit(item) {
      this.$router.push(`/notification?id=${item._id}`);
    },
    addNew() {
      this.$router.push('/notification');
    },
    loadData() {
      return this.service.fetchAll();
    },
    async sendNotification(item) {
      if (confirm('Are you sure?')) {
        try {
          this.loadingDialog = true
          await this.service.send(item);
          this.loadingDialog = false
        } catch (e) {
          console.log(e)
          this.errorVal = {
            'title': 'Error while changing password',
            'description': e?.response?.data?.message || 'Some Error Occurred'
          }
          this.error = true
          this.loadingDialog = false
        }
        this.loadingDialog = false
      }
    }
  }
};
</script>

<style lang="sass" scoped>
.chip
  font-weight: 500
  padding: 5px 10px
  border-radius: 50px
  background: lightgray
</style>

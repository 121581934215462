var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('data-table',{attrs:{"loader":_vm.loadData,"headers":_vm.headers,"title":"Recent Reviews","custom-filter":_vm.customFilter,"allow-add":false},on:{"add-new":function () {},"done":function($event){return _vm.$router.back()}},scopedSlots:_vm._u([{key:"company",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item && item.company_id ? item.company_id.name : '')+" ")]}},{key:"review-text",fn:function(ref){
var item = ref.item;
return [(item.review.description && item.review.description.length > 150)?_c('span',[_vm._v(_vm._s(item.review.description.toString().substring(0, 150))+"...")]):(item.review.description)?_c('span',[_vm._v(_vm._s(item.review.description))]):_vm._e()]}},{key:"rating",fn:function(ref){
var item = ref.item;
return [_c('v-rating',{attrs:{"dense":"","small":"","color":"amber","background-color":"amber","value":item.review.rating}})]}},{key:"date",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate2(item.review.review_date, 'MM/DD/YYYY hh:mm a'))+" ")]}},{key:"actions",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticStyle:{"padding":"5px","border-radius":"6px","background":"rgba(0,0,0,0.4)"},attrs:{"small":"","color":"white"},on:{"click":function($event){return _vm.openReview(item)}}},[_vm._v(" mdi-clipboard ")])]}}])}),_c('v-dialog',{attrs:{"width":"50%"},model:{value:(_vm.reviewDialog),callback:function ($$v) {_vm.reviewDialog=$$v},expression:"reviewDialog"}},[(_vm.review)?_c('v-card',{staticClass:"px-6 py-4"},[_c('h3',{staticClass:"mb-2"},[_vm._v("Review Detail")]),_c('div',[_c('h5',{staticClass:"mb-2"},[_vm._v("Rating")]),_c('v-rating',{staticClass:"mb-2",attrs:{"value":_vm.review.review.rating,"dense":"","color":"amber","background-color":"amber"}}),(_vm.review.review.description)?_c('h5',[_vm._v("Review")]):_vm._e(),_c('p',[_vm._v(_vm._s(_vm.review.review.description))]),_c('h5',[_vm._v("Reviewer Name")]),_c('p',[_vm._v(_vm._s(_vm.review.review.reviewer_name))])],1),_c('h3',{staticClass:"mb-2"},[_vm._v("Company Detail")]),_c('div',[_c('h5',[_vm._v("Name")]),_c('p',[_vm._v(_vm._s(_vm.review.company_id.name))]),_c('h5',[_vm._v("Email")]),_c('p',[_vm._v(_vm._s(_vm.review.company_id.email))]),_c('h5',[_vm._v("Phone")]),_c('p',[_vm._v(_vm._s(_vm.review.company_id.phone))])])]):_vm._e()],1),_c('loading-dialog',{attrs:{"message":"Loading..."},model:{value:(_vm.loadingDialog),callback:function ($$v) {_vm.loadingDialog=$$v},expression:"loadingDialog"}}),_c('ErrorDialog',{attrs:{"error":_vm.errorVal},model:{value:(_vm.error),callback:function ($$v) {_vm.error=$$v},expression:"error"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }
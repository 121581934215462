var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('data-table',{attrs:{"loader":_vm.loadData,"headers":_vm.headers,"title":"Users","allow-add":_vm.getUser() ? _vm.getUser().scopes.includes('users:new') : false,"delete-handler":_vm.getUser() ? _vm.getUser().scopes.includes('users:delete') ?  _vm.service.delete : null : null,"edit-handler":_vm.getUser() ? _vm.getUser().scopes.includes('users:edit') ? _vm.edit : null : null},on:{"add-new":_vm.addNew,"done":function($event){return _vm.$router.back()}},scopedSlots:_vm._u([{key:"createdAt",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(item.createdAt))+" ")]}},{key:"name",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.first_name)+" "+_vm._s(item.last_name)+" ")]}},{key:"scopes",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.scopes.join(', ').substr(0, 80) + '...')+" ")]}},{key:"extra-actions",fn:function(ref){
var item = ref.item;
return [(_vm.getUser() && _vm.getUser().scopes.includes('users:edit'))?_c('v-icon',{staticClass:"mr-1",staticStyle:{"padding":"5px","border-radius":"6px","background":"rgba(0,0,0,0.4)"},attrs:{"small":"","color":"white"},on:{"click":function($event){return _vm.openChangePassword(item)}}},[_vm._v(" mdi-lock ")]):_vm._e()]}}])}),_c('v-dialog',{attrs:{"width":"400"},model:{value:(_vm.changePassword),callback:function ($$v) {_vm.changePassword=$$v},expression:"changePassword"}},[_c('v-card',{staticStyle:{"padding":"40px"}},[_c('v-form',{ref:"changePassword"},[_c('p',{staticClass:"span-2 form__title mb-0"},[_vm._v("Change Password")]),_c('p',{staticClass:"span-2 mb-6"},[_vm._v("This will change password for this admin.")]),_c('v-text-field',{attrs:{"label":"Password","type":_vm.showPassword ? 'text' : 'password',"rules":[_vm.required()],"dense":"","outlined":""},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}}),_c('v-text-field',{attrs:{"label":"Confirm Password","rules":[function (v) { return (v && v === _vm.password) || 'Passwords does not match'; }],"type":_vm.showPassword ? 'text' : 'password',"dense":"","outlined":""},model:{value:(_vm.confirmPassword),callback:function ($$v) {_vm.confirmPassword=$$v},expression:"confirmPassword"}}),_c('v-checkbox',{staticStyle:{"margin-top":"-15px"},attrs:{"label":"Show Password"},model:{value:(_vm.showPassword),callback:function ($$v) {_vm.showPassword=$$v},expression:"showPassword"}}),_c('v-btn',{staticStyle:{"width":"100%"},attrs:{"elevation":"0","color":"primary"},on:{"click":_vm.changePasswordSubmit}},[_vm._v("Submit ")])],1)],1)],1),_c('loading-dialog',{attrs:{"message":"Loading..."},model:{value:(_vm.loadingDialog),callback:function ($$v) {_vm.loadingDialog=$$v},expression:"loadingDialog"}}),_c('ErrorDialog',{attrs:{"error":_vm.errorVal},model:{value:(_vm.error),callback:function ($$v) {_vm.error=$$v},expression:"error"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }
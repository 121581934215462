<template>
  <v-card class="mx-auto my-12" style="padding: 40px" width="600px">
    <v-form ref="changePassword">
      <p class="span-2 form__title mb-0">Change Password</p>
      <p v-if="!isUpdated" class="span-2 mb-6">Your password will be updated.</p>
      <p v-if="isUpdated" class="span-2 mb-6 green--text">Your password was updated.</p>
      <v-text-field
          v-model="oldPassword"
          label="Old Password"
          :type="showPassword ? 'text' : 'password'"
          :rules="[required()]"
          dense
          outlined
      />
      <v-text-field
          v-model="password"
          label="Password"
          :type="showPassword ? 'text' : 'password'"
          :rules="[required()]"
          dense
          outlined
      />
      <v-text-field
          v-model="confirmPassword"
          label="Confirm Password"
          :rules="[(v) => (v && v === password) || 'Passwords does not match']"
          :type="showPassword ? 'text' : 'password'"
          dense
          outlined
      />
      <v-checkbox
          v-model="showPassword"
          label="Show Password"
          style="margin-top: -15px"
      />
      <v-btn
          style="width: 100%"
          elevation="0"
          color="primary"
          @click="submit"
      >Submit
      </v-btn>
    </v-form>
    <loading-dialog v-model="loadingDialog" message="Loading..."/>
    <ErrorDialog v-model="error" :error="errorVal"/>
  </v-card>
</template>

<script>
import {required} from "@/utils/validators";
import {UsersService} from "@/services/users-service";
import ErrorDialog from "@/components/ErrorDialog";
import LoadingDialog from "../../components/LoadingDialog";
import {getUser} from "@/utils/local";

export default {
  name: "Security",
  components: {ErrorDialog, LoadingDialog},
  data() {
    return {
      changePassword: false,
      password: null,
      confirmPassword: null,
      oldPassword: null,
      showPassword: false,
      loading: false,
      service: new UsersService(),

      isUpdated: false,
      loadingDialog: false,
      error: false,
      errorVal: {},
    }
  },
  methods: {
    required,
    getUser,
    async submit() {
      if (this.$refs.changePassword.validate()) {
        this.isUpdated = false
        const user = getUser()
        this.loadingDialog = true
        if (this.password !== this.confirmPassword) {
          this.errorVal = {
            title: 'Couldn\'t confirm password',
            description: 'The provided new password does not match with confirmed password. '
          }
          this.error = true
          this.loadingDialog = false
          return
        }
        try {
          const data = {
            person_id: user._id,
            oldPassword: this.oldPassword,
            newPassword: this.password
          }
          await this.service.changePassword(data)
          this.loadingDialog = false
          this.isUpdated = true
          this.$refs.changePassword.reset()
        } catch (e) {
          console.log(e.data)
          this.errorVal = {
            title: 'Error',
            description: e?.data?.message ?? 'Some Error occurred'
          }
          this.loadingDialog = false
          this.error = true
        }
      }
    },
  }
}
</script>

<style scoped>

</style>

import { initializeApp } from "firebase/app";
import { getRemoteConfig } from "firebase/remote-config";

const firebaseConfig = {
    apiKey: "AIzaSyAnfz8suodnZXmMXBlXN3Hs8gz-a3l8NyQ",
    authDomain: "reputation-rooster-c59b9.firebaseapp.com",
    projectId: "reputation-rooster-c59b9",
    storageBucket: "reputation-rooster-c59b9.appspot.com",
    messagingSenderId: "143022851210",
    appId: "1:143022851210:web:69ade9a6aca9e72e805973",
    measurementId: "G-GKTHG2C1JG"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const remoteConfig = getRemoteConfig(app);
<template>
  <div>
    <data-table
        :loader="loadData"
        :headers="headers"
        title="Locations"
        :allow-add="false"
        :view-handler="getUser() ? getUser().scopes.includes('locations:detail') ? viewLocation : null : null"
        @done="$router.back()"
        class="elevation-5"
    >
      <template #address="{item}">
        <span>{{ formatAddress(item) }}</span>
      </template>
      <template #name="{item}">
        <div class="d-flex align-center justify-start">
          <v-avatar v-if="!item.logo" color="primary" class="white--text mr-3" size="42">
            {{ resolveInitial(item.name) }}
          </v-avatar>
          <v-avatar v-else class="mr-3 elevation-1" size="42">
            <img :src="$axios.defaults.baseURL + '/uploads/' + item.logo.name" referrerpolicy="no-referrer"
                 width="100%" height="100%" style="object-fit: contain"/>
          </v-avatar>
          <p class="ma-0">{{ item.name }}</p>
        </div>
      </template>
    </data-table>

    <loading-dialog v-model="loadingDialog" message="Sending Notification"/>
  </div>
</template>

<script>
import DataTable from '../../components/DataTable';
import LoadingDialog from "../../components/LoadingDialog";
import {getUser,resolveInitial, formatAddress} from '@/utils/local';
import {LocationsService} from "@/services/locations-service";


export default {
  components: {LoadingDialog, DataTable},

  data: () => ({
    loading: false,
    loadingDialog: false,
    service: new LocationsService(),

    headers: [
      {
        text: 'Location Name',
        value: 'name',
        sortable: false,
      },
      {
        text: 'Email',
        value: 'email',
        sortable: false,
      },
      {
        text: 'Phone',
        value: 'phone',
        sortable: false,
      },
      {
        text: 'Address',
        value: 'address',
        sortable: false,
      },
      {
        text: 'Timezone',
        value: 'timezone',
        sortable: false,
      }
    ]
  }),

  methods: {
    getUser,
    resolveInitial,
    formatAddress,
    viewLocation(item) {
      this.$router.push('/location?id=' + item._id);
    },
    loadData() {
      return this.service.fetchAll();
    },
  }
};
</script>

<style lang="sass" scoped>
.chip
  font-weight: 500
  padding: 5px 10px
  border-radius: 50px
  background: lightgray
</style>

import axios from 'axios';
import { SCHEDULER_URL } from '../utils/local';

export class BusinessLeadsService {
    async uploadFile(_file) {
        const formData = new FormData();
        formData.append('file', _file);
        return (await axios.post(SCHEDULER_URL + '/save-file', formData)).data;
    }

    async create(_data) {
        // return (await axios.post('https://scheduler.reputationrooster.com/google-my-business', _data)).data;
        return (await axios.post(SCHEDULER_URL + '/google-my-business/new', _data)).data;
    }

    async download() {
        return (await axios.get(SCHEDULER_URL + '/download-file')).data;
    }

    async checkHistory() {
        return (await axios.get(SCHEDULER_URL + '/check-file'));
    }
}

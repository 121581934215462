var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('data-table',{attrs:{"loader":_vm.loadData,"headers":_vm.headers,"title":"Links","allow-add":false,"delete-handler":_vm.getUser()
        ? _vm.getUser().scopes.includes('links:delete')
          ? _vm.service.delete
          : null
        : null,"edit-handler":null},on:{"done":function($event){return _vm.$router.back()}},scopedSlots:_vm._u([{key:"additional-sections",fn:function(){return [(_vm.getUser() && _vm.getUser().scopes.includes('links:create'))?_c('div',{staticClass:"px-4 py-2",style:(_vm.$vuetify.breakpoint.mdAndUp ? 'max-width: 80%' : '')},[_c('h2',{style:(_vm.$vuetify.breakpoint.smAndUp ? '' : 'font-size: 22px')},[_vm._v(" Shorten and Track Your Links ")]),_c('p',[_vm._v(" Streamlined URL shortener with click tracking for actionable insights. "),_c('br'),_vm._v(" Enter your long URL below and witness the magic of brevity! ")]),_c('v-row',{style:(_vm.$vuetify.breakpoint.mdAndUp ? 'max-width: 80%' : ''),attrs:{"dense":"","no-gutters":!_vm.$vuetify.breakpoint.mdAndUp}},[_c('v-col',{attrs:{"cols":"12","md":"10"}},[_c('v-form',{ref:"form",staticClass:"d-flex"},[_c('v-row',{attrs:{"dense":"","no-gutters":!_vm.$vuetify.breakpoint.mdAndUp}},[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-text-field',{attrs:{"rules":[_vm.required()],"outlined":"","dense":"","label":"Title"},model:{value:(_vm.link.name),callback:function ($$v) {_vm.$set(_vm.link, "name", $$v)},expression:"link.name"}})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-text-field',{attrs:{"rules":[_vm.required()],"outlined":"","dense":"","label":"Long URL"},model:{value:(_vm.link.long_url),callback:function ($$v) {_vm.$set(_vm.link, "long_url", $$v)},expression:"link.long_url"}})],1)],1)],1)],1),_c('v-col',{attrs:{"cols":"12","md":"2"}},[_c('v-btn',{attrs:{"color":"primary","elevation":"0","width":_vm.$vuetify.breakpoint.mdAndUp ? '' : '100%'},on:{"click":_vm.createLink}},[_vm._v("Shorten & Track! ")])],1)],1)],1):_vm._e()]},proxy:true},{key:"createdAt",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(item.createdAt))+" ")]}},{key:"long_url",fn:function(ref){
        var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":"","color":"primary"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
return [_c('p',_vm._g({},on),[_vm._v(" "+_vm._s(item.long_url ? item.long_url.length > 50 ? item.long_url.substring(0, 50) + '...' : item.long_url : '')+" ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.long_url))])])]}},{key:"short_url",fn:function(ref){
        var item = ref.item;
return [_c('p',{staticClass:"ma-0 pointer primary--text",on:{"click":function($event){return _vm.copyCode(item.short_url)}}},[_vm._v(" "+_vm._s(item.short_url)+" ")])]}}])}),_c('loading-dialog',{attrs:{"message":"Loading..."},model:{value:(_vm.loadingDialog),callback:function ($$v) {_vm.loadingDialog=$$v},expression:"loadingDialog"}}),_c('ErrorDialog',{attrs:{"error":_vm.errorVal},model:{value:(_vm.error),callback:function ($$v) {_vm.error=$$v},expression:"error"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }
import axios from 'axios';

export class CampaignService {
    async fetchEmailCampaign() {
        return (await axios.get('/campaign/stats')).data;
    }

    async fetchSmsCampaign() {
        return (await axios.get('/campaign/stats?type=1')).data;
    }
}

import BusinessLeadForm from "@/views/business-leads/BusinessLeadForm";

const businessLeadsRoutes = [
        {
            name: ' Business Leads',
            path: '/business-leads',
            component: BusinessLeadForm
        } ,
];

export const BusinessLeadsRouter = businessLeadsRoutes.filter(function (x) {
    return x !== null;
})

import axios from 'axios';

export class RecentReviewsService {
    async fetchAll() {
        return (await axios.get('/story/latest-reviews')).data;
    }

    async disable(id, status) {
        return (await axios.patch('/person/' + id + '/update-status', status)).data;
    }
}

// Css Imports
import './assets/style.sass';
import 'filepond/dist/filepond.min.css';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css';
import 'filepond-plugin-media-preview/dist/filepond-plugin-media-preview.css';
import 'filepond-plugin-pdf-preview/dist/filepond-plugin-pdf-preview.css';

// Defaults Imports
import Vue from 'vue';
import App from './App.vue';
import vuetify from './plugins/vuetify';
import router from './router';
import axios from 'axios';
import VueChartJs from 'vue-chartjs';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import OneSignalVue from 'onesignal-vue';
import Toast from 'vue-toastification';
import 'vue-toastification/dist/index.css';
import { registerPlugin } from 'filepond';

registerPlugin(FilePondPluginImagePreview);

export const baseUrl = process.env.VUE_APP_BASE_URL;
console.log('env', process.env.NODE_ENV);
console.log('Base Url', process.env.VUE_APP_BASE_URL);

// axios.defaults.baseURL = 'https://192.168.1.4:3000';
// axios.defaults.baseURL = 'https://api.reputationrooster.com'
// axios.defaults.baseURL = 'https://api-staging.reputationrooster.com'

Vue.config.productionTip = false;

Vue.prototype.$axios = axios;

axios.defaults.baseURL = baseUrl;

axios.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('auth_token');
    if (token) {
      config.headers.common['Authorization'] = 'bearer ' + token;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  (response) => {
    if (response.status === 200 || response.status === 201) {
      return Promise.resolve(response);
    } else {
      return Promise.reject(response);
    }
  },
  (error) => {
    if (error.response.status) {
      switch (error.response.status) {
        case 401:
          localStorage.removeItem('auth_token');
          localStorage.removeItem('auth_user');

          break;
      }
      return Promise.reject(error.response);
    }
  }
);

Vue.use(Toast, {
  position: 'bottom-right'
});
Vue.use(VueChartJs);
Vue.use(OneSignalVue);

new Vue({
  vuetify,
  VueChartJs,
  router,
  render: (h) => h(App)
}).$mount('#app');

import axios from 'axios';

export class NotificationsService {
    async fetchAll() {
        return (await axios.get('/notification-templates')).data;
    }

    async fetchOne(id) {
        return (await axios.get('/notification-templates/' + id)).data;
    }

    async create(notification) {
        return (await axios.post('/notification-templates', notification)).data;
    }

    async test(notification) {
        return (await axios.post('/notification-templates/test', notification)).data;
    }

    async send(notification) {
        return (await axios.post('/notification-templates/' + notification._id + '/send', notification)).data;
    }

    async delete(notification) {
        return (await axios.delete('/notification-templates/' + notification._id)).data;
    }

    async update(notification) {
        return (await axios.patch('/notification-templates/' + notification._id, notification)).data;
    }
}

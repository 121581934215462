<template>
    <div>
        <v-card class="pa-4 mb-4" elevation="5">
            <v-card-title>EMAIL Request Statistics</v-card-title>
            <v-simple-table>
                <template #default>
                    <thead v-if="$vuetify.breakpoint.mdAndUp">
                    <tr>
                        <th class="text-left">
                            Subject
                        </th>
                        <th class="text-left">
                            Type
                        </th>
                        <th class="text-left">
                            Sent
                        </th>
                        <th class="text-left">
                            Opened
                        </th>
                        <th class="text-left">
                            Clicked
                        </th>
                        <th class="text-left">
                            Goal
                        </th>
                    </tr>
                    </thead>
                    <tbody v-if="$vuetify.breakpoint.mdAndUp">
                    <tr v-if="!emailCampaign || emailCampaign.length === 0">
                        <td>No Record Found!</td>
                    </tr>
                    <tr v-for="(message,i) in emailCampaign"
                        :key="i">
                        <td class="subject">
                            <div class="d-flex align-center">
                                <img v-if="i === 0" :src="require('@/assets/badge-1.png')" height="50px" width="50px"/>
                                <img v-if="i === 1" :src="require('@/assets/badge-2.png')" height="50px" width="50px"/>
                                <img v-if="i === 2" :src="require('@/assets/badge-3.png')" height="50px" width="50px"/>
                                <img v-if="i === 3" :src="require('@/assets/badge-4.png')" height="50px" width="50px"/>
                                <p class="ma-0 ml-3">{{ message.subject }}</p>
                            </div>
                        </td>
                        <td>{{ getType(message.type) }}</td>
                        <td>{{ message.sent || 0 }}</td>
                        <td>{{ message.type === 0 ? message.opened || 0 : "-" }}</td>
                        <td>{{ message.type === 0 ? message.clicked || 0 : "-" }}</td>
                        <td>{{ message.scored || 0 }}</td>
                    </tr>
                    </tbody>
                    <tbody v-else>
                    <tr v-if="!emailCampaign || emailCampaign.length === 0">
                        <td>No Record Found!</td>
                    </tr>
                    <tr v-for="(message,i) in emailCampaign"
                        :key="i" class="v-data-table__mobile-table-row">
                        <td class="subject v-data-table__mobile-row">
                            <div class="v-data-table__mobile-row__header">Badge</div>
                            <div class="v-data-table__mobile-row__cell d-flex align-center justify-space-between">
                                <p class="ma-0 mr-2">Badge {{ i + 1 }}</p>
                            </div>
                        </td>
                        <td class="subject v-data-table__mobile-row">
                            <div class="v-data-table__mobile-row__header">Subject</div>
                            <div class="v-data-table__mobile-row__cell d-flex align-center justify-space-between">
                                <p class="ma-0 mr-2">{{ i + 1 }}. {{ message.subject }}</p>
                            </div>
                        </td>
                        <td class="v-data-table__mobile-row">
                            <div class="v-data-table__mobile-row__header">Type</div>
                            <div class="v-data-table__mobile-row__cell">{{ getType(message.type) }}</div>
                        </td>
                        <td class="v-data-table__mobile-row">
                            <div class="v-data-table__mobile-row__header">Sent</div>
                            <div class="v-data-table__mobile-row__cell">{{ message.sent || 0 }}</div>
                        </td>
                        <td class="v-data-table__mobile-row">
                            <div class="v-data-table__mobile-row__header">Opened</div>
                            <div class="v-data-table__mobile-row__cell">
                                {{ message.type === 0 ? message.opened || 0 : "-" }}
                            </div>
                        </td>
                        <td class="v-data-table__mobile-row">
                            <div class="v-data-table__mobile-row__header">Clicked</div>
                            <div class="v-data-table__mobile-row__cell">
                                {{ message.type === 0 ? message.clicked || 0 : "-" }}
                            </div>
                        </td>
                        <td class="v-data-table__mobile-row">
                            <div class="v-data-table__mobile-row__header">Scored</div>
                            <div class="v-data-table__mobile-row__cell">{{ message.scored || 0 }}</div>
                        </td>
                    </tr>
                    </tbody>
                </template>
            </v-simple-table>
        </v-card>

        <v-card class="pa-4 mt-4" elevation="5">
            <v-card-title>SMS Request Statistics</v-card-title>
            <v-simple-table>
                <template #default>
                    <thead v-if="$vuetify.breakpoint.mdAndUp">
                    <tr>
                        <th class="text-left">
                            Subject
                        </th>
                        <th class="text-left">
                            Type
                        </th>
                        <th class="text-left">
                            Sent
                        </th>
                        <th class="text-left">
                            Opened
                        </th>
                        <th class="text-left">
                            Clicked
                        </th>
                        <th class="text-left">
                            Goal
                        </th>
                    </tr>
                    </thead>
                    <tbody v-if="$vuetify.breakpoint.mdAndUp">
                    <tr v-if="!smsCampaign || smsCampaign.length === 0">
                        <td>No Record Found!</td>
                    </tr>
                    <tr v-for="(message,i) in getSmsCampaignOnly"
                        :key="i">
                        <td class="subject">
                            <div class="d-flex align-center">
                                <img v-if="i === 0" :src="require('@/assets/badge-1.png')" height="50px" width="50px" :alt="i"/>
                                <img v-if="i === 1" :src="require('@/assets/badge-2.png')" height="50px" width="50px" :alt="i"/>
                                <img v-if="i === 2" :src="require('@/assets/badge-3.png')" height="50px" width="50px" :alt="i"/>
                                <img v-if="i === 3" :src="require('@/assets/badge-4.png')" height="50px" width="50px" :alt="i"/>
                                <p class="ma-0 ml-3">{{ message.subject }}</p>
                            </div>
                        </td>
                        <td >{{ getType(message.type) }}</td>
                        <td>{{ message.sent || 0 }}</td>
                        <td>{{ message.type === 0 ? message.opened || 0 : "-" }}</td>
                        <td>{{ message.type === 0 ? message.clicked || 0 : "-" }}</td>
                        <td>{{ message.scored || 0 }}</td>
                    </tr>
                    </tbody>
                    <tbody v-else>
                    <tr v-if="!smsCampaign || smsCampaign.length === 0">
                        <td>No Record Found!</td>
                    </tr>
                    <tr v-for="(message,i) in getSmsCampaignOnly"
                        :key="i" class="v-data-table__mobile-table-row">
                        <td class="subject v-data-table__mobile-row">
                            <div class="v-data-table__mobile-row__header">Badge</div>
                            <div class="v-data-table__mobile-row__cell d-flex align-center justify-space-between">
                                <p class="ma-0 mr-2">Badge {{ i + 1 }}</p>
                            </div>
                        </td>
                        <td class="subject v-data-table__mobile-row">
                            <div class="v-data-table__mobile-row__header">Subject</div>
                            <div class="v-data-table__mobile-row__cell d-flex align-center justify-space-between">
                                <p class="ma-0 mr-2">{{ i + 1 }}. {{ message.subject }}</p>
                            </div>
                        </td>
                        <td class="v-data-table__mobile-row">
                            <div class="v-data-table__mobile-row__header">Type</div>
                            <div class="v-data-table__mobile-row__cell">{{ getType(message.type) }}</div>
                        </td>
                        <td class="v-data-table__mobile-row">
                            <div class="v-data-table__mobile-row__header">Sent</div>
                            <div class="v-data-table__mobile-row__cell">{{ message.sent || 0 }}</div>
                        </td>
                        <td class="v-data-table__mobile-row">
                            <div class="v-data-table__mobile-row__header">Opened</div>
                            <div class="v-data-table__mobile-row__cell">
                                {{ message.type === 0 ? message.opened || 0 : "-" }}
                            </div>
                        </td>
                        <td class="v-data-table__mobile-row">
                            <div class="v-data-table__mobile-row__header">Clicked</div>
                            <div class="v-data-table__mobile-row__cell">
                                {{ message.type === 0 ? message.clicked || 0 : "-" }}
                            </div>
                        </td>
                        <td class="v-data-table__mobile-row">
                            <div class="v-data-table__mobile-row__header">Scored</div>
                            <div class="v-data-table__mobile-row__cell">{{ message.scored || 0 }}</div>
                        </td>
                    </tr>
                    </tbody>
                </template>
            </v-simple-table>
        </v-card>
        <LoadingDialog v-model="loading" message="Loading Requests Info..."/>
        <ErrorDialog v-model="error" :error="errorVal"/>
    </div>
</template>

<script>
import {CampaignService} from "@/services/campaign-service";
import LoadingDialog from "@/components/LoadingDialog";
import ErrorDialog from "@/components/ErrorDialog";

export default {
    name: "RequestsStatsView",
    components: {ErrorDialog, LoadingDialog},
    data() {
        return {
            service: new CampaignService(),
            emailCampaign: null,
            smsCampaign: null,
            loading: false,
            error: false,
            errorVal: {},
        }
    },
    computed: {
       getSmsCampaignOnly() {
           if(this.smsCampaign?.length) {
               return this.smsCampaign.filter(camp => camp.type === 1)
           } else {
               return [];
           }
       }
    },
    mounted() {
        this.getData()
    },
    methods: {
        async getData() {
            try {
                this.loading = true;
                this.emailCampaign = await this.service.fetchEmailCampaign();
                this.smsCampaign = await this.service.fetchSmsCampaign();

                if (this.emailCampaign.length > 0) this.emailCampaign.sort(this.compare)
                if (this.smsCampaign.length > 0) this.smsCampaign.sort(this.compare)
                this.loading = false;
            } catch (e) {
                console.log(e)
                this.errorVal = {
                    title: 'Error',
                    description: e?.response?.data?.message || 'Some Error Occurred'
                }
                this.error = true
                this.loading = false;
            }
        },
        getType(type) {
            switch (type) {
                case 0:
                    return "Email";
                case 1:
                    return "SMS";
            }
        },
        getTemplateTitle(i) {
            switch (i) {
                case 0:
                    return "First Message";
                case 1:
                    return "Second Message";
                case 2:
                    return "Third Message";
                case 3:
                    return "Fourth Message";
            }
        },
        compare(a, b) {
            if (a.scored < b.scored) {
                return -1;
            }
            if (a.scored > b.scored) {
                return 1;
            }
            return 0;
        }
    }
}
</script>

<style scoped>

</style>

<template>
  <v-container>
    <v-row v-if="location">
      <v-col cols="12" md="4">
        <v-card class="py-4 px-6" elevation="5" style="height: 100%">
          <div class="d-flex flex-wrap">
            <div class="flex-grow-1">
              <h3 class="mb-2">Location Info</h3>
              <div class="mb-2">
                <v-avatar v-if="location.logo" class="elevation-1" size="58">
                  <img :src="$axios.defaults.baseURL + '/uploads/' + location.logo.name" height="100%"
                       style="object-fit: contain"
                       width="100%"/>
                </v-avatar>
                <v-avatar v-else color="primary" size="58" style="color: white">
              <span>
                {{ resolveInitial(location.name) }}
              </span>
                </v-avatar>
              </div>
              <h4>{{ location.name }}</h4>
              <h6> {{ location.email }}</h6>
              <h6 class="mb-2"> {{ location.phone }}</h6>
            </div>
            <div class="flex-grow-1">
              <h3 class="mb-2">Owner Info</h3>
              <div class="mb-2">
                <v-avatar v-if="customer.image" size="58" style="border: 1px solid #99265f">
                  <img v-if="customer.image.name.includes('http')" :src="customer.image.name"
                       referrerpolicy="no-referrer"/>
                  <img v-else :src="$axios.defaults.baseURL + '/uploads/' + customer.image.name"/>
                </v-avatar>
                <v-avatar v-else color="primary" size="58" style="color: white">
              <span>
                {{ resolveInitial(customer.first_name + ' ' + customer.last_name) }}
              </span>
                </v-avatar>
              </div>
              <h4>{{ customer.first_name }} {{ customer.last_name }}</h4>
              <h6> {{ customer.username }}</h6>
              <h6 class="mb-2"> {{ customer.phone }}</h6>
            </div>
          </div>
          <h4>Location Address</h4>
          <p>{{ formatAddress(location) }}</p>
        </v-card>
      </v-col>
      <v-col cols="12" md="8">
        <v-card class="pa-4" elevation="5" style="height: 100%">
          <h3 class="mb-6">Overall Statistics</h3>
          <v-row v-if="stat">
            <v-col cols="12" md="3">
              <div class="d-flex mb-3 align-center">
                <v-avatar class="mr-3" color="primary" rounded size="58">
                  <v-icon color="#fff">mdi-star-outline</v-icon>
                </v-avatar>
                <div>
                  <h5>Reviews</h5>
                  <h4 class="primary--text">{{ stat.reviews }}</h4>
                </div>
              </div>
            </v-col>
            <v-col cols="12" md="3">
              <div class="d-flex mb-3 align-center">
                <v-avatar class="mr-3" color="green" rounded size="58">
                  <v-icon color="#fff">mdi-account-multiple-outline</v-icon>
                </v-avatar>
                <div>
                  <h5>Relationships</h5>
                  <h4 class="primary--text">{{ stat.contacts }}</h4>
                </div>
              </div>
            </v-col>
            <v-col cols="12" md="3">
              <div class="d-flex mb-3 align-center">
                <v-avatar class="mr-3" color="yellow" rounded size="58">
                  <v-icon color="#fff">mdi-web</v-icon>
                </v-avatar>
                <div>
                  <h5>Sites</h5>
                  <h4 class="primary--text">{{ stat ? stat.sites : '' }}</h4>
                </div>
              </div>
            </v-col>
            <v-col cols="12" md="3">
              <div class="d-flex mb-3 align-center">
                <v-avatar class="mr-3" color="blue" rounded size="58">
                  <v-icon color="#fff">mdi-share-outline</v-icon>
                </v-avatar>
                <div>
                  <h5>Requests</h5>
                  <h4 class="primary--text">{{ stat ? stat.invites : '' }}</h4>
                </div>
              </div>
            </v-col>
          </v-row>
          <p>Since joining</p>
          <div class="d-flex custom-gap">
            <div>
              <h5>New Reviews</h5>
              <h4 class="primary--text">{{ stat ? stat.new_reviews : '' }}</h4>
            </div>
            <div>
              <h5>Previous Reviews</h5>
              <h4 class="primary--text">{{ stat ? stat.old_reviews : '' }}</h4>
            </div>
          </div>
        </v-card>
      </v-col>
      <v-col cols="12">
        <CampaignsStat
            :email-campaign="emailCampaignStat"
            :sms-campaign="smsCampaignStat"
        />
      </v-col>
      <v-col cols="6">
        <v-card class="pa-4">
          <h3 class="mb-6">Review Sites</h3>
          <div v-for="(site,i) in sites" :key="i">
            <SiteViewer :platform="site" class="mb-3"/>
          </div>
          <div v-if="sites.length <= 0">
            <p class="text-center">No Review sites connected yet!</p>
          </div>
        </v-card>
      </v-col>
      <v-col cols="6">
        <v-card class="pa-4">
          <h3 class="mb-6">Social Platforms</h3>
          <div v-for="(platform,i) in platforms" :key="i">
            <PlatformViewer :platform="platform" class="mb-3"/>
          </div>
          <div v-if="platforms.length <= 0">
            <p class="text-center">No Social Platform connected yet!</p>
          </div>
        </v-card>
      </v-col>
    </v-row>

    <LoadingDialog v-model="loading" message="Loading Location Info..."/>
    <ErrorDialog v-model="error" :error="errorVal"/>
  </v-container>
</template>

<script>
import {CustomersService} from "@/services/customers-service";
import LoadingDialog from "@/components/LoadingDialog";
import {formatAddress, formatDate, resolveInitial} from "@/utils/local";
import ErrorDialog from "@/components/ErrorDialog";
import CampaignsStat from "@/components/CampaignStat";
import {LocationsService} from "@/services/locations-service";
import {SitesService} from "@/services/sites-service";
import SiteViewer from "@/components/SiteViewer";
import PlatformViewer from "@/components/PlatformViewer";

export default {
  name: "LocationDetail",
  components: {PlatformViewer, SiteViewer, ErrorDialog, LoadingDialog, CampaignsStat},
  data() {
    return {
      loading: false,
      error: false,
      errorVal: {},
      customer: null,
      location: null,
      product: null,
      locations: [],
      sites: [],
      platforms: [],
      stat: null,
      emailCampaignStat: null,
      smsCampaignStat: null,
      customerService: new CustomersService(),
      sitesService: new SitesService(),
      service: new LocationsService(),
      headers: [
        {
          text: 'Location Name',
          value: 'name',
          sortable: false,
        },
        {
          text: 'Email',
          value: 'email',
          sortable: false,
        },
        {
          text: 'Phone',
          value: 'phone',
          sortable: false,
        },
        {
          text: 'Address',
          value: 'address',
          sortable: false,
        },
        {
          text: 'Timezone',
          value: 'timezone',
          sortable: false,
        }
      ]
    }
  },
  mounted() {
    this.loadCustomer()
  },
  methods: {
    formatDate,
    resolveInitial,
    formatAddress,
    async loadCustomer() {
      if (!this.$route.query.id) return;
      try {
        this.loading = true;
        this.location = await this.service.fetchOne(this.$route.query.id)
        const customer = this.location.company_team.find((o) => o.role === 0);
        this.customer = customer.person
        this.stat = await this.service.getStats(this.$route.query.id);
        // Email Campaign Stat
        this.emailCampaignStat = await this.service.getEmailCampaign(
            this.$route.query.id
        );
        // Sms Campaign Stat
        this.smsCampaignStat = await this.service.getSmsCampaign(
            this.$route.query.id
        );
        const locations = await this.customerService.fetchLocations(this.$route.query.id);
        this.locations = locations.filter((o) => {
          const owner = o.company_team.find((i) => i.role === 0)
          return owner.person._id === this.$route.query.id
        })
        this.product = await this.customerService.getProduct(this.customer.subscription.plan.product);
        this.sites = await this.sitesService.fetchAllReviewSites(this.location._id)
        this.platforms = await this.sitesService.fetchAllPlatforms(this.location._id)
        this.loading = false;
      } catch (e) {
        console.log(e)
        this.errorVal = {
          title: 'Error',
          description: e?.response?.data?.message || 'Some Error Occured'
        }
        this.error = true
        this.loading = false;
      }
    },
    loadLocations() {
      return this.locations
    },
    viewLocation(item) {
      this.$router.push('/location?id=' + item._id);
    }
  }
}
</script>

<style scoped>
.custom-gap {
  gap: 50px
}

</style>

<template>
  <v-container>
    <v-row v-if="customer">
      <v-col cols="12" md="4">
        <v-card class="py-4 px-6" elevation="5" style="height: 100%">
          <div class="d-flex justify-space-between">
            <div class="mb-2">
              <v-avatar
                  v-if="customer.image"
                  size="58"
                  style="border: 1px solid #99265f"
              >
                <img
                    v-if="customer.image.name.includes('http')"
                    :src="customer.image.name"
                    referrerpolicy="no-referrer"
                />
                <img
                    v-else
                    :src="
                  $axios.defaults.baseURL + '/uploads/' + customer.image.name
                "
                />
              </v-avatar>
              <v-avatar v-else color="primary" size="58" style="color: white">
              <span>
                {{
                  resolveInitial(customer.first_name + ' ' + customer.last_name)
                }}
              </span>
              </v-avatar>
            </div>

            <div>


              <v-badge v-if="!customer.account_deleted" color="#00FF21" dot left />
              <v-badge v-else color="red" dot left />

              <v-btn
                  class="ml-2"
                  :disabled="loading"
                  color="primary"
                  depressed
                  small
                  @click="changeAccountStatus"
              >
                {{ customer.account_deleted ? 'Active' : 'Deactive' }} Now
              </v-btn>
            </div>
          </div>
          <h3>{{ customer.first_name }} {{ customer.last_name }}</h3>
          <h5 class="mb-2">{{ customer.username }}</h5>
          <h5 class="mb-2">{{ customer.phone }}</h5>
          <v-btn
              color="primary"
              depressed
              small
              @click="updatePasswordDialog = true"
          >
            Update Password
          </v-btn>
        </v-card>
      </v-col>

      <v-col cols="12" md="8">
        <v-card class="pa-4" elevation="5" style="height: 100%">
          <div class="d-flex justify-space-between">
            <h3 class="mb-3">Current Subscription</h3>
            <v-btn v-if="customer.subscription && customer.subscription.status !== 'canceled'"
                   color="primary"
                   small
                   @click="onCancelSubscription"
            >
              Cancel Subscription
            </v-btn>
          </div>
          <p v-if="customer && customer.subscription && customer.subscription.status !== 'canceled' && product">
            <span class="font-weight-bold">{{ customer.first_name }} {{ customer.last_name }}</span>
            is currently subscribed to
            <span class="font-weight-bold"> {{ product.name }} plan.</span>
          </p>
          <p v-else>No subscription</p>

          <p v-if="customer.subscription">
            <span class="font-weight-bold">Subscription Status: </span>
            {{ customer.subscription.status.charAt(0).toUpperCase() + customer.subscription.status.slice(1) }}
          </p>

          <p v-if="customer.subscription
                             && (customer.subscription.status === 'active' || customer.subscription.status === 'trialing')"
          >
            <span class="font-weight-bold">Next billing date: </span>
            {{ formatDate(customer.subscription.current_period_end) }}
          </p>

          <p v-if="customer.subscription
                              && customer.subscription.status !== 'active'
                              && customer.subscription.status !== 'trialing'"
          >
            Subscription was cancelled. <br/>
            Reason: <br/>
            {{
              customer.subscription_cancellation_reason ? customer.subscription_cancellation_reason : 'N/A'
            }}
          </p>


          <h3 class="mb-6">Overall Statistics</h3>
          <v-row v-if="stat">
            <v-col cols="12" md="4">
              <div class="d-flex mb-3 align-center">
                <v-avatar class="mr-3" color="green" rounded size="58">
                  <v-icon color="#fff">mdi-home-city</v-icon>
                </v-avatar>
                <div>
                  <h5>Locations</h5>
                  <h4 class="primary--text">{{ stat.locations }}</h4>
                </div>
              </div>
            </v-col>
            <v-col cols="12" md="4">
              <div class="d-flex mb-3 align-center">
                <v-avatar class="mr-3" color="pink" rounded size="58">
                  <v-icon color="#fff">mdi-star</v-icon>
                </v-avatar>
                <div>
                  <h5>Reviews</h5>
                  <h4 class="primary--text">{{ stat.reviews }}</h4>
                </div>
              </div>
            </v-col>
            <v-col cols="12" md="4">
              <div class="d-flex mb-3 align-center">
                <v-avatar class="mr-3" color="purple" rounded size="58">
                  <v-icon color="#fff">mdi-account-multiple</v-icon>
                </v-avatar>
                <div>
                  <h5>Relationships</h5>
                  <h4 class="primary--text">{{ stat.relationships }}</h4>
                </div>
              </div>
            </v-col>
          </v-row>
        </v-card>
      </v-col>

      <!--            <v-col cols="12">-->
      <!--                <CampaignsStat-->
      <!--                        :email-campaign="emailCampaignStat"-->
      <!--                        :sms-campaign="smsCampaignStat"-->
      <!--                />-->
      <!--            </v-col>-->

      <v-col v-if="locations && locations.length > 0" cols="12">
        <data-table
            :allow-add="false"
            :headers="headers"
            :loader="loadLocations"
            :view-handler="
            getUser()
              ? getUser().scopes.includes('locations:detail')
                ? viewLocation
                : null
              : null
          "
            class="elevation-5"
            title="Locations"
            @done="$router.back()"
        >
          <template #address="{ item }">
            <span>{{ formatAddress(item) }}</span>
          </template>
          <template #name="{ item }">
            <div class="d-flex align-center justify-start">
              <v-avatar
                  v-if="!item.logo"
                  class="white--text mr-3"
                  color="primary"
                  size="42"
              >
                {{ resolveInitial(item.name) }}
              </v-avatar>
              <v-avatar v-else class="mr-3 elevation-1" size="42">
                <img
                    :src="$axios.defaults.baseURL + '/uploads/' + item.logo.name"
                    height="100%"
                    referrerpolicy="no-referrer"
                    style="object-fit: contain"
                    width="100%"
                />
              </v-avatar>
              <p class="ma-0">{{ item.name }}</p>
            </div>
          </template>
        </data-table>
      </v-col>
    </v-row>

    <!-- Update Password Dialog -->
    <v-dialog v-model="updatePasswordDialog" max-width="500px">
      <v-card>
        <v-card-title>
          <span class="headline">Update Password</span>
        </v-card-title>
        <v-card-text>
          <v-form ref="form" class="mb-n6" lazy-validation>
            <v-text-field
                v-model="password"
                :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                :counter="passwordCounter"
                :rules="passwordRules"
                :type="showPassword ? 'text' : 'password'"
                hint="At least 8 characters"
                label="Password"
                name="password"
                outlined
                @click:append="showPassword = !showPassword"
            ></v-text-field>
            <v-text-field
                v-model="confirmPassword"
                :append-icon="showConfirmPassword ? 'mdi-eye' : 'mdi-eye-off'"
                :rules="confirmPasswordRules"
                :type="showConfirmPassword ? 'text' : 'password'"
                label="Confirm Password"
                name="confirmPassword"
                outlined
                @click:append="showConfirmPassword = !showConfirmPassword"
            ></v-text-field>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="updatePasswordHandler"> Update</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <LoadingDialog v-model="loading" :message="loadingMessage"/>
    <ErrorDialog v-model="error" :error="errorVal"/>
  </v-container>
</template>

<script>
import {CustomersService} from '@/services/customers-service';
import LoadingDialog from '@/components/LoadingDialog';
import DataTable from '@/components/DataTable';
import {formatAddress, formatDate, getUser, resolveInitial} from '@/utils/local';
import ErrorDialog from '@/components/ErrorDialog';
// import CampaignsStat from '@/components/CampaignStat';

export default {
  name: 'CustomerDetail',
  components: {
    // CampaignsStat,
    ErrorDialog,
    LoadingDialog,
    DataTable
  },
  data() {
    return {
      loading: false,
      loadingMessage: 'Loading Customer Info...',
      error: false,
      errorVal: {},
      customer: null,
      product: null,
      locations: [],
      stat: null,
      emailCampaignStat: null,
      smsCampaignStat: null,
      service: new CustomersService(),

      // Update Password
      updatePasswordDialog: false,
      password: '',
      confirmPassword: '',
      passwordRules: [
        (v) => !!v || 'Password is required',
        (v) => v.length >= 8 || 'Password must be at least 8 characters'
      ],
      passwordCounter: 0,
      confirmPasswordRules: [
        (v) => !!v || 'Confirm Password is required',
        (v) => v === this.password || 'Confirm Password must match Password'
      ],
      showPassword: false,
      showConfirmPassword: false,

      headers: [
        {
          text: 'Location Name',
          value: 'name',
          sortable: false
        },
        {
          text: 'Email',
          value: 'email',
          sortable: false
        },
        {
          text: 'Phone',
          value: 'phone',
          sortable: false
        },
        {
          text: 'Address',
          value: 'address',
          sortable: false
        },
        {
          text: 'Timezone',
          value: 'timezone',
          sortable: false
        }
      ]
    };
  },
  async mounted() {
    await this.loadCustomer();
  },
  methods: {
    getUser,
    formatDate,
    resolveInitial,
    formatAddress,
    async updatePasswordHandler() {
      if (!this.$refs.form.validate()) return;

      try {
        this.loadingMessage = 'Updating Password...';
        this.loading = true;
        await this.service.updatePassword(this.$route.query.id, {
          newPassword: this.password
        });
        this.updatePasswordDialog = false;
        this.password = '';
        this.confirmPassword = '';
        this.loading = false;
        this.$toast.success('Password Updated Successfully');
      } catch (e) {
        this.errorVal = {
          title: 'Error',
          description: e?.response?.data?.message || 'Some Error Occurred'
        };
        this.error = true;
        this.loading = false;
      }
    },
    async onCancelSubscription() {
      if (confirm(`Are you sure, you want to cancel the subscription of ${this.customer.first_name}`)) {
        const data = {
          subscription_id: this.customer.subscription.id,
          person: this.customer._id,
          cancel: true,
          reason: "",
          immediately: true
        };

        this.loadingMessage = 'Cancelling, please wait...'
        this.loading = true;

        try {
          await this.service.cancelSubscription(data);
          this.customer = await this.service.fetchOne(this.$route.query.id);
          this.product = await this.service.getProduct(this.customer.subscription.plan.product);
          this.loading = false;
        } catch (e) {
          console.log(e);
          this.loading = false;
        }
      }
    },
    async loadCustomer() {
      if (!this.$route.query.id) return;
      try {
        this.loading = true;
        this.customer = await this.service.fetchOne(this.$route.query.id);
        this.stat = await this.service.getStats(this.$route.query.id);

        // Email Campaign Stat
        // this.emailCampaignStat = await this.service.getEmailCampaign(this.$route.query.id);
        // console.log('emailCampaign: ', this.emailCampaignStat)
        // Sms Campaign Stat
        // this.smsCampaignStat = await this.service.getSmsCampaign(this.$route.query.id);
        // console.log('smsCampaignStat: ', this.smsCampaignStat)

        const locations = await this.service.fetchLocations(this.$route.query.id);
        // console.log('locations: ', locations)

        this.locations = locations.filter((o) => {
          const owner = o.company_team.find((i) => i.role === 0);
          if (owner && owner.person)
            return owner.person._id === this.$route.query.id;
          return [];
        });

        if (this.customer.subscription
            && this.customer.subscription.plan
            && this.customer.subscription.plan.product
        ) {
          this.product = await this.service.getProduct(this.customer.subscription.plan.product);
          // console.log('product: ', this.product)
        }
        this.loading = false;
      } catch (e) {
        console.log('error in loadCustomer: ', e);
        this.errorVal = {
          title: 'Error',
          description: e?.response?.data?.message || 'Some Error Occurred'
        };
        this.error = true;
        this.loading = false;
      }
    },
    async changeAccountStatus() {
      if (confirm('Are you sure, want to change customer account?')) {
        try {
          this.loading = true;
          this.loadingMessage = 'Changing Account...';
          await this.service.update(this.customer._id, {account_deleted: !this.customer.account_deleted});
          await this.loadCustomer();
          this.$toast.success('Customer account updated!');
        } catch (e) {
          console.log('Error in changeAccountStatus: ', e);
        } finally {
          this.loading = false
        }
      }
    },
    loadLocations() {
      return this.locations;
    },
    viewLocation(item) {
      this.$router.push('/location?id=' + item._id);
    },
  }
};
</script>

<style scoped></style>

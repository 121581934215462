<template>
  <div>
    <v-app-bar app dark color="primary" elevate-on-scroll>
      <v-app-bar-nav-icon @click="drawer = !drawer"/>

      <v-card-title>Reputation Rooster Admin</v-card-title>
      <v-spacer/>

      <profile-popup/>
    </v-app-bar>
    <v-main class="main-bg">
      <v-container>
        <router-view/>
      </v-container>
    </v-main>
    <v-navigation-drawer v-model="drawer" app>
      <img src="../assets/logo-head.png" alt="logo" class="logo" height="70"/>
      <v-divider/>
      <template v-for="(route, key) in routes">
        <v-list-item
            v-if="route.isVisible"
            :key="key"
            class="route"
            color="#da57a7"
            active-class="route--active"
            exact
            dense
            :to="route.to"
        >
          <v-list-item-icon>
            <v-icon v-text="route.icon"/>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title v-text="route.title"/>
          </v-list-item-content>
        </v-list-item>
        <v-divider v-else-if="route.isDivider" :key="key" style="margin: 10px 20px"/>
      </template>
    </v-navigation-drawer>
  </div>
</template>

<script>
import ProfilePopup from "./ProfilePopup";
import {getUser} from "@/utils/local";
import {required} from "@/utils/validators";

export default {
  name: 'Dashboard',
  components: {ProfilePopup},
  data: () => ({
    drawer: true,
    user: {},
    message: null,
    loading: false,
  }),
  methods: {
    getUser,
    required,
  },
  computed: {
    routes() {
      return [
        {
          to: '/',
          title: 'Dashboard',
          icon: 'mdi-view-dashboard-variant',
          isVisible: this.getUser()?.scopes?.includes('dashboard:view')
        },
        (this.getUser()?.scopes?.includes('dashboard:view') && (this.getUser()?.scopes?.includes('customers:view') || this.getUser()?.scopes?.includes('locations:view') || this.getUser()?.scopes?.includes('request-stats:view'))) ? {isDivider: true} : {isDivider: false},
        {
          to: '/customers',
          title: 'Customers',
          icon: 'mdi-account-group',
          isVisible: this.getUser()?.scopes?.includes('customers:view')
        },
        {
          to: '/locations',
          title: 'Locations',
          icon: 'mdi-city',
          isVisible: this.getUser()?.scopes?.includes('locations:view')
        },
        {
          to: '/recent-reviews',
          title: 'Recent Reviews',
          icon: 'mdi-star',
          isVisible: this.getUser()?.scopes?.includes('recent-reviews:view')
        },
        {
          to: '/review-sites',
          title: 'Review Sites',
          icon: 'mdi-star',
          isVisible: this.getUser()?.scopes?.includes('review-sites:view')
        },
        {
          to: '/request-stats',
          title: 'Requests Stats',
          icon: 'mdi-file',
          isVisible: this.getUser()?.scopes?.includes('request-stats:view')
        },
          ((this.getUser()?.scopes?.includes('customers:view') || this.getUser()?.scopes?.includes('locations:view') || this.getUser()?.scopes?.includes('request-stats:view')))
          && (this.getUser()?.scopes?.includes('coupons:view') || this.getUser()?.scopes?.includes('notifications:view')) ? {isDivider: true} : {isDivider: false},
        {
          to: '/coupons',
          title: 'Coupons',
          icon: 'mdi-tag',
          isVisible: this.getUser()?.scopes?.includes('coupons:view')
        },
        {
          to: '/notifications',
          title: 'Notifications',
          icon: 'mdi-bell',
          isVisible: this.getUser()?.scopes?.includes('notifications:view')
        },
        {
          to: '/business-leads',
          title: 'Business Leads',
          icon: 'mdi-folder-marker',
          isVisible: this.getUser()?.scopes?.includes('business-leads:view')
        },
        {
          to: '/links',
          title: 'Links',
          icon: 'mdi-link',
          isVisible: this.getUser()?.scopes?.includes('links:view')
        },
          ((this.getUser()?.scopes?.includes('business-leads:view') || this.getUser()?.scopes?.includes('coupons:view') || this.getUser()?.scopes?.includes('notifications:view'))
              && (this.getUser()?.scopes?.includes('users:view'))) ? {isDivider: true} : {isDivider: false},
        {
          to: '/users',
          title: 'Users',
          icon: 'mdi-account-group',
          isVisible: this.getUser()?.scopes?.includes('users:view')
        },
      ];
    }
  }
};
</script>

<style lang="sass" scoped>
.logo
  display: block
  margin: 5px auto
  border-radius: 10px

.route
  margin: 5px
  text-align: left
  overflow: hidden
  border-radius: 4px
  font-size: 20px !important
  font-family: "Roboto", sans-serif

  i
    font-size: 20px

  &--active i
    color: inherit

  &--active
    color: #7c1e1e

.main-bg
  background: linear-gradient(to bottom, #7c1e1e 300px, white 300px)
</style>
